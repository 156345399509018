import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavbarUser from '../../../components/UserComponents/NavbarUser';
import Footer from '../../../components/Footer';

const UserProfileUser = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');

                if (!token) {
                    throw new Error('No authentication token found');
                }

                console.log('Token:', token);

                const response = await axios.get('https://timestation.clickbooster.io/api/get-user', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                console.log('Response:', response.data);

                if (response.data && response.data.user) {
                    setUserData(response.data.user);
                } else {
                    throw new Error("No data received from server");
                }
            } catch (err) {
                console.error('Error details:', err.response || err);

                if (err.response?.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                    return;
                }

                setError(err.response?.data?.message || err.message);
            }
        };

        fetchUserData();
    }, [navigate]);

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response?.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, [navigate]);

    return (
        <>
            <NavbarUser />
            {error && <div className="flex items-center justify-center h-screen text-red-500">Error: {error}</div>}
            <div className="container px-4 py-10 mx-auto">
                <div className="flex justify-center">
                    <div className="w-full lg:w-7/12">
                        <h1 className="mb-6 text-2xl font-bold text-center">User Information</h1>
                        {userData && (
                            <div className="p-6 bg-white rounded-lg shadow-lg">
                                <table className="w-full">
                                    <tbody className="divide-y divide-gray-200">
                                        <tr>
                                            <td className="py-3 text-sm font-medium text-gray-700">Name</td>
                                            <td className="py-3 text-sm text-gray-600">{userData.name}</td>
                                        </tr>
                                        <tr>
                                            <td className="py-3 text-sm font-medium text-gray-700">Username</td>
                                            <td className="py-3 text-sm text-gray-600">{userData.username}</td>
                                        </tr>
                                        <tr>
                                            <td className="py-3 text-sm font-medium text-gray-700">Email</td>
                                            <td className="py-3 text-sm text-gray-600">{userData.email}</td>
                                        </tr>
                                        <tr>
                                            <td className="py-3 text-sm font-medium text-gray-700">Phone Number</td>
                                            <td className="py-3 text-sm text-gray-600">{userData.phone_number}</td>
                                        </tr>
                                        <tr>
                                            <td className="py-3 text-sm font-medium text-gray-700">Date of Birth</td>
                                            <td className="py-3 text-sm text-gray-600">{userData.date_of_birth}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default UserProfileUser;