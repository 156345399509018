import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo-doubly.png';
import defaultProfileImage from '../assets/images/logo.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [profileError, setProfileError] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedUserId = localStorage.getItem('user_id');

        if (storedUsername) {
            setUsername(storedUsername);
        } else {
            setUsername('Guest'); // Fallback to 'Guest' if no username is found
        }

        if (!storedUserId) {
            console.warn('User ID not found in localStorage.');
        }
    }, []);

    useEffect(() => {
        const fetchProfileImage = async () => {
            setLoadingProfile(true);
            setProfileError(null);

            try {
                const response = await fetch('https://timestation.clickbooster.io/api/get-profile-picture', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: localStorage.getItem('user_id'),
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch profile image: ${response.statusText}`);
                }

                const data = await response.json();

                if (data && data.profile_picture_url) {
                    setProfileImageUrl(data.profile_picture_url);
                } else {
                    setProfileImageUrl(defaultProfileImage);
                    setProfileError('Profile image not available.');
                }
            } catch (error) {
                setProfileImageUrl(defaultProfileImage);
                setProfileError(error.message || 'Failed to load profile image.');
            } finally {
                setLoadingProfile(false);
            }
        };

        fetchProfileImage();
    }, []);

    useEffect(() => {
        setIsOpen(false);
        setProfileOpen(false);
    }, [location.pathname]);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/Login');
        window.location.reload();
    };

    const navigationItems = useMemo(() => [
        { path: '/HomeSecond', label: 'Home' },
        { path: '/Employee', label: 'Employees' },
        { path: '/Departments', label: 'Departments' },
        { path: '/RunReport', label: 'Reports' },
        // { path: '/ActivityLogs', label: 'ActivityLogs' },
        { path: '/Settings', label: 'Settings' },
        { path: '/Support', label: 'Support' },
    ], []);

    const toggleMenu = () => setIsOpen(prev => !prev);
    const toggleProfileMenu = () => setProfileOpen(prev => !prev);

    return (
        <nav className="p-4 bg-black shadow-md">
            <div className="container flex items-center justify-between mx-auto">
                {/* Logo */}
                <Link to="/HomeSecond" className="text-lg font-bold text-blue-900">
                    <img src={logo} width="130px" alt="Logo" />
                </Link>

                {/* Hamburger Icon for Mobile */}
                <button
                    className="text-white lg:hidden"
                    onClick={toggleMenu}
                    aria-label="Toggle navigation menu"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-8 h-8"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                        />
                    </svg>
                </button>

                {/* Large Screen Links */}
                <div className="hidden space-x-4 lg:flex">
                    {navigationItems.map((item) => (
                        <Link
                            key={item.path}
                            to={item.path}
                            className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700"
                        >
                            {item.label}
                        </Link>
                    ))}
                </div>

                {/* Profile Section (Hidden on Mobile) */}
                <div className="relative hidden lg:flex">
                    <button
                        onClick={toggleProfileMenu}
                        className="flex items-center p-2 space-x-2 font-semibold text-white rounded-md hover:bg-blue-700"
                        aria-label="Toggle Profile Menu"
                    >
                        <img
                            src={profileImageUrl || defaultProfileImage}
                            alt="User Avatar"
                            className={`object-cover w-14 h-14 border-4 rounded-full shadow-lg ${loadingProfile ? 'animate-pulse bg-gray-200' : ''}`}
                        />
                        <span>{username}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>
                    {profileOpen && (
                        <div className="absolute right-0 z-50 w-56 mt-2 bg-white rounded-md shadow-lg">
                            <div className="flex items-center p-4 border-b">
                                <img
                                    src={profileImageUrl || defaultProfileImage}
                                    alt="User Avatar"
                                    className="object-cover w-10 h-10 rounded-full"
                                />
                                <div className="ml-3">
                                    <h4 className="text-sm font-bold text-gray-800">{username}</h4>
                                    <p className="text-xs text-gray-500">View your profile</p>
                                </div>
                            </div>
                            <div className="p-2">
                                <Link to="/Account_Setting" className="flex items-center px-4 py-2 text-sm text-gray-800 hover:bg-gray-100">
                                    Account Settings
                                </Link>
                                <button
                                    onClick={handleLogout}
                                    className="flex items-center w-full px-4 py-2 text-sm text-left text-red-600 hover:bg-gray-100"
                                >
                                    Logout
                                </button>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            {/* Mobile Navigation Menu */}
            {isOpen && (
                <div className="flex flex-col p-4 space-y-2 bg-black lg:hidden">
                    {navigationItems.map((item) => (
                        <Link
                            key={item.path}
                            to={item.path}
                            className="block p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700"
                        >
                            {item.label}
                        </Link>
                    ))}
                </div>
            )}
        </nav>
    );
};

export default React.memo(Navbar);
