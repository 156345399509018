import React, { useState, useEffect } from "react";
import Button from "../Button";
import "./PromoBanner.css";
import axios from "axios";
import { Modal, ModalHeader, ModalBody } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PromoBanner = () => {
  const [showClockModal, setShowClockModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState("");
  const [employees, setEmployees] = useState([]);
  const [newEmployeeId, setNewEmployeeId] = useState("");

  // Fetch employee clock-in status on component mount
  useEffect(() => {
    const fetchEmployeeStatus = async () => {
      try {
        const response = await axios.get("https://timestation.clickbooster.io/api/employee-status");
        setEmployees(response.data.map((employee) => ({
          ...employee,
          elapsedTime: null, // Initialize elapsed time
        })));
      } catch (error) {
        console.error("Failed to fetch employee status:", error);
      }
    };

    fetchEmployeeStatus();
  }, []);

  const handleAddEmployee = async () => {
    if (!newEmployeeId.trim()) {
      toast.error("Please enter a valid Employee ID.");
      return;
    }

    setButtonLoading("validate");
    try {
      await axios.post("https://timestation.clickbooster.io/api/check-employee", {
        employee_id: newEmployeeId,
      });

      setEmployees((prev) => [
        ...prev,
        {
          id: newEmployeeId,
          clockInTime: null,
          elapsedTime: null,
          duration: null,
          clockedIn: false,
        },
      ]);
      setNewEmployeeId("");
      toast.success("Employee validated and added successfully!");
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to validate Employee ID. Please try again."
      );
    } finally {
      setButtonLoading("");
    }
  };

  const handleClockIn = async (employeeId) => {
    setButtonLoading(`clockIn:${employeeId}`);
    try {
      await axios.post("https://timestation.clickbooster.io/api/clock-in", {
        employee_id: employeeId,
      });

      setEmployees((prev) =>
        prev.map((emp) =>
          emp.id === employeeId
            ? { ...emp, clockInTime: new Date().toISOString(), duration: null, clockedIn: true }
            : emp
        )
      );
      toast.success(`Employee ${employeeId} clocked in successfully!`);
    } catch (error) {
      if (error.response?.data?.message === "Employee already clocked in without clocking out.") {
        setEmployees((prev) =>
          prev.map((emp) =>
            emp.id === employeeId
              ? { ...emp, clockInTime: new Date().toISOString(), clockedIn: true }
              : emp
          )
        );
        toast.error("Employee already clocked in. Please clock out first.");
      } else {
        toast.error(
          error.response?.data?.message || "Failed to clock in. Please try again."
        );
      }
    } finally {
      setButtonLoading("");
    }
  };

  const handleClockOut = async (employeeId) => {
    setButtonLoading(`clockOut:${employeeId}`);
    try {
      const response = await axios.post("https://timestation.clickbooster.io/api/clock-out", {
        employee_id: employeeId,
      });

      const { hours, minutes, total_minutes, time_out } = response.data.punch;

      setEmployees((prev) =>
        prev.map((emp) =>
          emp.id === employeeId
            ? {
              ...emp,
              clockInTime: null,
              duration: `${hours} hours, ${minutes} minutes (${total_minutes} total minutes)`,
              clockedIn: false,
            }
            : emp
        )
      );
      toast.success(`Employee ${employeeId} clocked out successfully at ${time_out}!`);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to clock out. Please try again."
      );
    } finally {
      setButtonLoading("");
    }
  };

  useEffect(() => {
    const timers = employees.map((emp) => {
      if (emp.clockInTime) {
        return setInterval(() => {
          const now = new Date();
          const clockInDate = new Date(emp.clockInTime);
          const elapsed = Math.floor((now - clockInDate) / 1000); // Elapsed time in seconds
          const minutes = Math.floor(elapsed / 60);
          const seconds = elapsed % 60;

          setEmployees((prev) =>
            prev.map((e) =>
              e.id === emp.id ? { ...e, elapsedTime: `${minutes} min ${seconds} sec` } : e
            )
          );
        }, 1000);
      }
      return null;
    });

    return () => timers.forEach((timer) => clearInterval(timer));
  }, [employees]);

  return (
    <div className="promo-banner">
      <ToastContainer />

      <div className="absolute inset-0 flex items-center justify-start px-8 bg-black bg-opacity-50 md:flex-row md:px-1">
        <div className="w-full max-w-lg p-8 ml-0 space-y-6 md:ml-20 bg-slate-200 rounded-xl lg:w-auto">
          <h2 className="text-2xl font-bold lg:text-4xl">
            Intelligence is yours with Ai Time Station as your new HR system
          </h2>
          <Button text="Sign Up for Free" link="/SignUp" width="50%" />
        </div>
      </div>

      <div className="two_buttons">
        <Button
          text="Clock In and Out"
          onClick={() => setShowClockModal(true)}
          className="custom-button"
        />
      </div>

      {showClockModal && (
        <Modal show={showClockModal} onHide={() => setShowClockModal(false)} centered>
          <ModalHeader className="flex justify-between text-white bg-gradient-to-r from-blue-500 to-indigo-600">
            <Modal.Title className="text-xl font-bold">Clock In/Out</Modal.Title>
            <button
              onClick={() => setShowClockModal(false)}
              className="text-white hover:text-gray-200 focus:outline-none"
            >
              ✕
            </button>
          </ModalHeader>
          <ModalBody className="bg-gray-100">
            <div className="p-4">
              {/* Employee ID Input Section */}
              <div className="mb-6">
                <label
                  htmlFor="newEmployeeId"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Enter Employee ID:
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    id="newEmployeeId"
                    value={newEmployeeId}
                    onChange={(e) => setNewEmployeeId(e.target.value)}
                    placeholder="Enter Employee ID"
                    className="flex-grow p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                  <button
                    onClick={handleAddEmployee}
                    disabled={buttonLoading === "validate"}
                    className={`px-4 py-2 font-semibold text-white rounded-lg shadow-md ${buttonLoading === "validate"
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700"
                      }`}
                  >
                    {buttonLoading === "validate" ? "Processing..." : "Validate"}
                  </button>
                </div>
              </div>

              {/* Employee Clock In/Out Section */}
              {employees.map((employee) => (
                <div
                  key={employee.id}
                  className="mb-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-4"
                >
                  <h4 className="text-lg font-bold text-gray-800">Employee ID: {employee.id}</h4>
                  {employee.elapsedTime && (
                    <p className="mt-2 text-sm text-blue-600">
                      Elapsed Time: <strong>{employee.elapsedTime}</strong>
                    </p>
                  )}
                  {employee.duration && (
                    <p className="mt-2 text-sm text-yellow-600">
                      Total Duration: <strong>{employee.duration}</strong>
                    </p>
                  )}
                  <div className="flex items-center gap-4 mt-4">
                    <button
                      onClick={() => handleClockIn(employee.id)}
                      disabled={employee.clockedIn || buttonLoading === `clockIn:${employee.id}`}
                      className={`w-full py-2 px-4 text-sm font-semibold rounded-lg ${employee.clockedIn || buttonLoading === `clockIn:${employee.id}`
                        ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                        : "bg-green-500 text-white hover:bg-green-600"
                        }`}
                    >
                      {buttonLoading === `clockIn:${employee.id}` ? "Processing..." : "Clock In"}
                    </button>
                    <button
                      onClick={() => handleClockOut(employee.id)}
                      disabled={!employee.clockedIn || buttonLoading === `clockOut:${employee.id}`}
                      className={`w-full py-2 px-4 text-sm font-semibold rounded-lg ${!employee.clockedIn || buttonLoading === `clockOut:${employee.id}`
                        ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                        : "bg-red-500 text-white hover:bg-red-600"
                        }`}
                    >
                      {buttonLoading === `clockOut:${employee.id}` ? "Processing..." : "Clock Out"}
                    </button>
                  </div>

                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default PromoBanner;
