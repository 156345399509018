import React, { useState } from "react";
import NavbarUser from "../../../components/UserComponents/NavbarUser";
import Footer from "../../../components/Footer";

const UserReport = () => {
    const [selectedReport, setSelectedReport] = useState("");

    const reports = [
        "Activity",
        "Daily Summary",
        "Department Summary",
        "Shift Details",
    ];

    return (
        <>
            <NavbarUser />
            <div className="p-4 my-28 sm:p-8">
                <h1 className="text-2xl font-bold text-blue-600 mb-6">Reports</h1>
                <div className="max-w-md mx-auto bg-blue-50 border border-blue-200 rounded-md p-4 shadow-md">
                    <label htmlFor="report" className="block text-lg font-semibold mb-2">
                        Select Report:
                    </label>
                    <select
                        id="report"
                        value={selectedReport}
                        onChange={(e) => setSelectedReport(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md text-gray-700 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="" disabled>
                            - Please Select -
                        </option>
                        {reports.map((report, index) => (
                            <option key={index} value={report}>
                                {report}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <Footer />

        </>
    );
};

export default UserReport;
