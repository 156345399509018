import React from "react";
import { FaApple, FaAndroid } from "react-icons/fa";
import Button from "./Button";

const DownloadSection = () => {

    const androidFilePath = "/Apk File/android.apk";
    const iOSFilePath = "/Apk File/ios.apk";

    return (
        <div className="bg-blue-50">
            <div className="container px-10 mx-auto md:px-24">
                <div className="flex flex-col items-center justify-center py-12">
                    <h2 className="mb-4 text-3xl font-semibold text-blue-600">Download TimeStation</h2>
                    <p className="max-w-lg mb-8 text-center text-gray-600">
                        Get the free Ai Time Station App for your smartphone or tablet. It's easy, just click on the download button for your device.
                    </p>
                    <div className="flex flex-col gap-8 md:flex-row">
                        {/* iOS Download */}
                        <div className="flex flex-col items-center p-6 transition-transform duration-300 transform bg-white rounded-lg shadow-lg cursor-pointer w-72 hover:scale-105 hover:shadow-2xl">
                            <FaApple className="mb-4 text-6xl text-gray-800" />
                            <h3 className="mb-2 text-lg font-bold text-blue-600">TimeStation for Apple iOS</h3>
                            <p className="mb-4 text-sm text-center text-gray-600">
                                For iOS devices running iOS 7.0 or higher, including iPhone, iPad, and iPod Touch.
                            </p>
                            <Button text="Download for iOS" link={iOSFilePath} width="100%" />
                        </div>

                        {/* Android Download */}
                        <div className="flex flex-col items-center p-6 transition-transform duration-300 transform bg-white rounded-lg shadow-lg cursor-pointer w-72 hover:scale-105 hover:shadow-2xl">
                            <FaAndroid className="mb-4 text-6xl text-green-500" />
                            <h3 className="mb-2 text-lg font-bold text-blue-600">TimeStation for Google Android</h3>
                            <p className="mb-4 text-sm text-center text-gray-600">
                                For Android smartphones and tablets running Android 2.3.3 or higher.
                            </p>
                            <Button text="Download for Android" link={androidFilePath} width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadSection;
