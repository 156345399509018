import React from 'react';
import styled from 'styled-components';


const Button = ({ 
  text = 'Button', 
  link = '#', 
  onClick, 
  width = 'auto', 
  target = '_self', 
  download = false 
}) => {
  return (
    <StyledWrapper width={width}>
      <a
        href={link} 
        onClick={onClick} 
        target={target} 
        rel={target === '_blank' ? 'noopener noreferrer' : undefined} 
        download={download} 
        className="button"
      >
        {text}
      </a>
    </StyledWrapper>
  );
};


const StyledWrapper = styled.div`
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    padding: 14px 20px;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    cursor: pointer;
    width: ${(props) => props.width}; /* Set width dynamically */
    min-width: 140px;
    white-space: nowrap;
    user-select: none;
    touch-action: manipulation;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  a:hover {
    background-color: #fff;
    color: #ffffff 
    transform: scale(1.05);
  }

  a:active {
    transform: scale(0.95);
  }
`;

export default Button;
