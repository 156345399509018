import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import { FaExclamationTriangle, FaPlus } from "react-icons/fa";

const Administrator = () => {
    return (
        <>
            <Navbar />
            <div className="container my-10  md:px-24 px-10">
                <div className="bg-gray-100 rounded-lg p-4 min-h-screen">
                    {/* Header Section */}
                    <h1 className="text-2xl font-bold text-blue-600 mb-4">Administrators</h1>

                    {/* Create New Administrator Button */}
                    <div className="flex justify-end mb-4">
                        <Button
                            text="Create New Department"
                            link="/CreateDepartment"
                            icon={<FaPlus />}
                            width="100%"
                        />
                    </div>

                    {/* Administrators Table */}
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                            <thead className="bg-blue-600 text-white">
                                <tr>
                                    <th className="p-4 text-left">Edit</th>
                                    <th className="p-4 text-left">Name</th>
                                    <th className="p-4 text-left">Email</th>
                                    <th className="p-4 text-left">Departments</th>
                                    <th className="p-4 text-left">Permissions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Administrator Row */}
                                <tr className="border-b">
                                    <td className="p-4 text-gray-500">Current User</td>
                                    <td className="p-4 text-gray-700 font-semibold">Shayan</td>
                                    <td className="p-4 text-gray-700">shayan@gmail.com</td>
                                    <td className="p-4 text-gray-700">All</td>
                                    <td className="p-4 text-gray-700">Full</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Administrator;
