import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import SearchForm from '../../components/SearchForm';

const Employee = () => {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingEmployee, setEditingEmployee] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        if (!userId) {
            toast.error('Please login to view employees');
            window.location.href = '/login';
            return;
        }
        fetchEmployees();
        fetchDepartments();
    }, []);

    const fetchEmployees = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            console.log('Fetching employees for user_id:', userId);

            if (!userId) {
                toast.error('Please login first');
                window.location.href = '/login';
                return;
            }

            const response = await fetch(`https://timestation.clickbooster.io/api/employees`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch employees');
            }

            const data = await response.json();
            console.log('Received employee data:', data);

            const userEmployees = data.filter(emp => emp.user_id === userId);
            setEmployees(userEmployees);

            if (userEmployees.length === 0) {
                // toast.info('No employees found for current user');
            }

        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to fetch employees');
            setEmployees([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchDepartments = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            const response = await fetch(`https://timestation.clickbooster.io/api/department/user/${userId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch departments');
            }

            const result = await response.json();
            setDepartments(result.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
            // toast.error('Failed to fetch departments');
        }
    };

    const deleteEmployee = async (employeeId) => {
        try {
            const userId = localStorage.getItem('user_id');
            const response = await fetch(`https://timestation.clickbooster.io/api/employees/${employeeId}?user_id=${userId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete employee');
            }

            toast.success('Employee deleted successfully');
            // Refresh the employees list
            fetchEmployees();
        } catch (error) {
            console.error('Error deleting employee:', error);
            toast.error('Failed to delete employee');
        }
    };

    const handleEditClick = (employee) => {
        setEditingEmployee(employee);
        setIsEditModalOpen(true);
    };

    const updateEmployee = async (employeeData) => {
        try {
            const userId = localStorage.getItem('user_id');
            const response = await fetch(`https://timestation.clickbooster.io/api/employees/${employeeData.id}?user_id=${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(employeeData)
            });

            if (!response.ok) {
                throw new Error('Failed to update employee');
            }

            toast.success('Employee updated successfully');
            setIsEditModalOpen(false);
            fetchEmployees(); // Refresh the list
        } catch (error) {
            console.error('Error updating employee:', error);
            toast.error('Failed to update employee');
        }
    };
    const handleBulkDelete = async () => {
        if (!window.confirm('Are you sure you want to delete selected employees?')) {
            return;
        }

        try {
            const empIds = `[${selectedEmployees.join(',')}]`; // Formats the array as a string like [54,64]
            const url = `https://timestation.clickbooster.io/api/allemployees?emp_id[]=${encodeURIComponent(empIds)}`;

            console.log("Deleting employees with URL:", url);  // Debug: Log the URL

            const response = await fetch(url, {
                method: 'POST', // Confirm this is the method expected by the backend
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                const responseBody = await response.text(); // Get the server's response text
                console.error('Server responded with:', responseBody); // Log detailed server response
                throw new Error(`Failed to delete employees: ${response.status} - ${responseBody}`);
            }

            toast.success('Selected employees deleted successfully');
            setSelectedEmployees([]); // Clear the selection
            fetchEmployees(); // Refresh the employee list
        } catch (error) {
            console.error('Error deleting employees:', error);
            toast.error(`Failed to delete selected employees: ${error.message}`);
        }
    };

    const handleSelectEmployee = (id) => {
        setSelectedEmployees(prev =>
            prev.includes(id) ? prev.filter(empId => empId !== id) : [...prev, id]
        );
    };

    const handleNameClick = (employee) => {
        // Find the department for this employee
        const department = departments.find(dept => dept.id === employee.department_id);

        // Navigate to the employee card page with state
        navigate(`/Employee/${employee.id}`, {
            state: {
                employee: {
                    ...employee,
                    department: department?.dp_name || 'Unknown Department'
                }
            }
        });
    };

    const handlePrintEmployee = async (employeeId) => {
        try {
            const response = await fetch(`https://timestation.clickbooster.io/api/employees/print/${employeeId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to generate PDF');
            }

            // Convert the response to blob
            const blob = await response.blob();

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link and trigger download
            const link = document.createElement('a');
            link.href = url;
            link.download = `employee_${employeeId}_details.pdf`;
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            toast.success('PDF generated successfully');
        } catch (error) {
            console.error('Error generating PDF:', error);
            toast.error('Failed to generate PDF');
        }
    };

    const EditModal = () => {
        const [formData, setFormData] = useState(editingEmployee || {});
        const userId = localStorage.getItem('user_id');

        useEffect(() => {
            if (editingEmployee) {
                // Find the department for this employee
                const department = departments.find(dept => dept.id === editingEmployee.department_id);

                console.log('Setting form data:', {
                    ...editingEmployee,
                    department_id: editingEmployee.department_id ? editingEmployee.department_id.toString() : '',
                    department_name: department ? department.dp_name : 'Unknown Department'
                });

                setFormData({
                    ...editingEmployee,
                    department_id: editingEmployee.department_id ? editingEmployee.department_id.toString() : '',
                    department_name: department ? department.dp_name : 'Unknown Department'
                });
            }
        }, [editingEmployee, departments]);

        // Filter departments based on user_id
        const userDepartments = departments.filter(dept => dept.user_id === userId);

        // Add console.log to debug departments
        console.log('User Departments:', userDepartments);
        console.log('Current formData:', formData);

        const handleSubmit = (e) => {
            e.preventDefault();
            updateEmployee(formData);
        };

        const handleChange = (e) => {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        };

        if (!isEditModalOpen) return null;

        return (

            <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-600 bg-opacity-50">
                <div className="flex items-center justify-center min-h-screen px-4">
                    <div className="relative w-full max-w-2xl p-6 bg-white rounded-lg shadow-xl">
                        {/* Modal Header */}
                        <div className="flex flex-wrap items-center justify-between mb-6 ">
                            <h2 className="text-2xl font-bold text-gray-900">Edit Employee</h2>
                            <button
                                onClick={() => setIsEditModalOpen(false)}
                                className="p-1 transition-colors rounded-full hover:bg-gray-100"
                            >
                                <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                        </div>

                        {/* Modal Body */}
                        <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                    {/* Name Field */}
                                    <div>
                                        <label className="block mb-1 text-sm font-medium text-gray-700">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name || ''}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        />
                                    </div>

                                    {/* Department ID Field */}
                                    <div>
                                        <label className="block mb-1 text-sm font-medium text-gray-700">
                                            Department
                                        </label>
                                        <select
                                            name="department_id"
                                            value={formData.department_id || ''}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        >
                                            <option value="">Select Department</option>
                                            {departments.map(dept => (
                                                <option
                                                    key={dept.id}
                                                    value={dept.id.toString()}
                                                >
                                                    {dept.dp_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block mb-1 text-sm font-medium text-gray-700">
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={formData.title || ''}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        />
                                    </div>



                                    {/* Employee ID  Field */}
                                    <div>
                                        <label className="block mb-1 text-sm font-medium text-gray-700">
                                            Employee ID
                                        </label>
                                        <input
                                            type="text"
                                            name="employee_id"
                                            value={formData.employee_id || ''}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        />
                                    </div>
                                    {/* Hourly Rate Field */}
                                    <div>
                                        <label className="block mb-1 text-sm font-medium text-gray-700">
                                            Hourly Rate ($)
                                        </label>
                                        <input
                                            type="number"
                                            step="0.01"
                                            name="hourly_rate"
                                            value={formData.hourly_rate || ''}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        />
                                    </div>


                                </div>

                                {/* Modal Footer */}
                                <div className="flex justify-end pt-6 mt-6 space-x-3 border-t">
                                    <button
                                        type="button"
                                        onClick={() => setIsEditModalOpen(false)}
                                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <ToastContainer />
            <Navbar />

            <SearchForm />
            <div className="container px-10 py-8 mx-auto">
                <div className="flex flex-wrap items-center justify-between m-2 mb-6">
                    <h1 className="text-2xl font-bold">Employees List</h1>
                    <button
                        className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                        onClick={() => window.location.href = '/CreateNewEmployee'}
                    >
                        Add New Employee
                    </button>
                    <button
                        className="px-4 py-2 mt-2 mb-4 ml-4 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                        onClick={handleBulkDelete}
                        disabled={selectedEmployees.length === 0}
                    >
                        Delete Selected Employees
                    </button>
                </div>

                {loading ? (
                    <div className="flex justify-center py-4">
                        <div className="text-lg">Loading employees...</div>
                    </div>
                ) : employees.length === 0 ? (
                    <div className="flex flex-col items-center justify-center py-8">
                        <div className="mb-4 text-lg text-gray-600">No employees found</div>
                        <button
                            className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                            onClick={() => window.location.href = '/CreateNewEmployee'}
                        >
                            Add Your First Employee
                        </button>
                    </div>
                ) : (
                    <div className="overflow-x-auto bg-white rounded-lg shadow-lg">
                        <table className="min-w-full table-auto">
                            <thead>
                                <tr className="bg-gray-100 border-b border-gray-200">
                                    <th className="px-6 py-4 text-sm font-semibold text-gray-700 uppercase">Select</th>

                                    <th className="px-6 py-4 text-sm font-semibold text-gray-700 uppercase">Name</th>
                                    <th className="px-6 py-4 text-sm font-semibold text-gray-700 uppercase">Employee ID</th>
                                    <th className="px-6 py-4 text-sm font-semibold text-gray-700 uppercase">Department</th>
                                    <th className="px-6 py-4 text-sm font-semibold text-gray-700 uppercase">Title</th>
                                    <th className="px-6 py-4 text-sm font-semibold text-gray-700 uppercase">Email</th>
                                    <th className="px-6 py-4 text-sm font-semibold text-gray-700 uppercase">Hourly Rate</th>
                                    <th className="px-6 py-4 text-sm font-semibold text-gray-700 uppercase">Permissions</th>
                                    <th className="px-6 py-4 text-sm font-semibold text-gray-700 uppercase">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees.map((employee, index) => {
                                    const department = departments.find(dept => dept.id === employee.department_id);
                                    return (
                                        <tr key={employee.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100`}>
                                            <td className="p-4">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedEmployees.includes(employee.id)}
                                                    onChange={() => handleSelectEmployee(employee.id)}
                                                />
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200">
                                                <div className="flex items-center">
                                                    <div className="flex items-center justify-center w-8 h-8 mr-3 text-white bg-blue-500 rounded-full">
                                                        {employee.name.charAt(0).toUpperCase()}
                                                    </div>
                                                    <button
                                                        onClick={() => handleNameClick(employee)}
                                                        className="text-sm font-medium text-blue-600 cursor-pointer hover:underline"
                                                    >
                                                        {employee.name}
                                                    </button>
                                                </div>
                                            </td>

                                            <td className="px-6 py-4 text-sm text-gray-900 border-b border-gray-200">
                                                {employee.employee_id}
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200">
                                                <span className="px-3 py-1 text-sm font-medium text-blue-600 bg-blue-100 rounded-full">
                                                    {department ? department.dp_name : 'Unknown Department'}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 text-sm text-gray-900 border-b border-gray-200">
                                                {employee.title}
                                            </td>
                                            <td className="px-6 py-4 text-sm text-gray-900 border-b border-gray-200">
                                                {employee.email}
                                            </td>
                                            <td className="px-6 py-4 text-sm font-medium text-gray-900 border-b border-gray-200">
                                                ${parseFloat(employee.hourly_rate).toFixed(2)}
                                            </td>
                                            <td className="px-6 py-4 text-sm text-gray-900 border-b border-gray-200">
                                                <div className="flex flex-col gap-1">
                                                    {employee.permission_app && (
                                                        <span className="px-2 py-1 text-xs text-green-600 bg-green-100 rounded">App Access</span>
                                                    )}
                                                    {employee.permission_site && (
                                                        <span className="px-2 py-1 text-xs text-purple-600 bg-purple-100 rounded">Site Access</span>
                                                    )}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200">
                                                <div className="flex space-x-3">
                                                    <button
                                                        onClick={() => handleEditClick(employee)}
                                                        className="px-3 py-1 text-sm text-indigo-600 transition-colors duration-200 border border-indigo-600 rounded-md hover:bg-indigo-600 hover:text-white"
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            if (window.confirm('Are you sure you want to delete this employee?')) {
                                                                deleteEmployee(employee.id);
                                                            }
                                                        }}
                                                        className="px-3 py-1 text-sm text-red-600 transition-colors duration-200 border border-red-600 rounded-md hover:bg-red-600 hover:text-white"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            <EditModal />
            <Footer />
        </>
    );
};

export default Employee;