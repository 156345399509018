import React, { useEffect, useState } from 'react';
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Modal from '../../components/Modal';

const Departments = () => {
    const [departments, setDepartments] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentDepartment, setCurrentDepartment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedDepartments, setSelectedDepartments] = useState([]);


    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        if (!userId) {
            toast.error('Please login to view departments');
            window.location.href = '/login';
            return;
        }
        fetchDepartments();
    }, []);

    const fetchDepartments = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            console.log('Fetching departments for user_id:', userId);

            if (!userId) {
                toast.error('Please login first');
                window.location.href = '/login';
                return;
            }

            const response = await fetch('https://timestation.clickbooster.io/api/departments', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error response:', errorData);
                throw new Error(`Failed to fetch departments: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            console.log('Received department data:', data);

            const userDepartments = data.filter(dept => dept.user_id === parseInt(userId));
            setDepartments(userDepartments);

            if (userDepartments.length === 0) {
                toast.info('No departments found for current user');
            }

        } catch (error) {
            console.error('Error:', error);
            // toast.error('Failed to fetch departments');
            setDepartments([]);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (department) => {
        setCurrentDepartment(department);
        setIsModalOpen(true);
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this department?')) {
            return;
        }

        try {
            const response = await fetch(`https://timestation.clickbooster.io/api/departments/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json(); // Get the server's response as JSON
                throw new Error(`Failed to delete department: ${response.status} - ${errorData.message}`); // Extract message from JSON
            }

            toast.success('Department deleted successfully');
            await fetchDepartments();
        } catch (error) {
            console.error('Error deleting department:', error);
            toast.error(`Error: ${error.message}`); // Show user-friendly error
        }
    };


    const handleBulkDelete = async () => {
        if (!window.confirm('Are you sure you want to delete selected departments?')) {
            return;
        }

        try {
            const depIds = `[${selectedDepartments.join(',')}]`; // Formats the array as a string like [54,64]
            const url = `https://timestation.clickbooster.io/api/alldepartments?dep_id[]=${encodeURIComponent(depIds)}`;

            console.log("Deleting departments with URL:", url);  // Debug: Log the URL

            const response = await fetch(url, {
                method: 'POST', // Confirm this is the method expected by the backend
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json(); // Get the server's response as JSON
                throw new Error(`Failed to delete departments: ${response.status} - ${errorData.message}`); // Extract message from JSON
            }

            toast.success('Selected departments deleted successfully');
            setSelectedDepartments([]); // Clear the selection
            await fetchDepartments(); // Refresh the department list
        } catch (error) {
            console.error('Error deleting departments:', error);
            toast.error(`Error: ${error.message}`); // Show user-friendly error
        }
    };


    const handleSelectDepartment = (id) => {
        const newSelection = [...selectedDepartments];
        if (newSelection.includes(id)) {
            setSelectedDepartments(newSelection.filter(depId => depId !== id));
        } else {
            newSelection.push(id);
            setSelectedDepartments(newSelection);
        }
    };


    const handleSaveChanges = async (event) => {
        event.preventDefault();
        try {
            const userId = localStorage.getItem('user_id');
            const response = await fetch(`https://timestation.clickbooster.io/api/departments/${currentDepartment.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    ...currentDepartment,
                    user_id: parseInt(userId),
                    exclude_from_reports: currentDepartment.exclude_from_reports || false,
                    use_company_settings: currentDepartment.use_company_settings || false,
                    assign_to_all_employees: currentDepartment.assign_to_all_employees || false
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update department');
            }

            toast.success('Department updated successfully');
            setIsModalOpen(false);
            fetchDepartments();
        } catch (error) {
            console.error('Error updating department:', error);
            toast.error('Failed to update department');
        }
    };

    const handleDepartmentClick = (departmentId) => {
        window.location.href = `/Departments/${departmentId}`;
    };

    return (
        <>
            <ToastContainer />
            <Navbar />
            <div className="container px-10 my-20 md:px-14">
                <div className="p-4 bg-gray-100 rounded-lg shadow-lg py-14">
                    <h1 className="mb-4 text-2xl font-bold text-blue-600">Departments</h1>
                    <button
                        className="px-4 py-2 mb-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                        onClick={() => window.location.href = '/CreateDepartment'}
                    >
                        <FaPlus className="inline-block mr-2" /> Add New Department
                    </button>

                    <button
                        className="px-4 py-2 mb-4 ml-4 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                        onClick={handleBulkDelete}
                        disabled={selectedDepartments.length === 0}
                    >
                        Delete Selected Departments
                    </button>

                    {loading ? (
                        <div className="flex justify-center py-4">
                            <div className="text-lg">Loading departments...</div>
                        </div>
                    ) : departments.length === 0 ? (
                        <div className="flex flex-col items-center justify-center py-8">
                            <div className="mb-4 text-lg text-gray-600">No departments found</div>
                            <button
                                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700"
                                onClick={() => window.location.href = '/CreateDepartment'}
                            >
                                <FaPlus className="inline-block mr-2" /> Create New Department
                            </button>
                        </div>
                    ) : (
                        <div className="mt-4 overflow-x-auto bg-white rounded shadow">
                            <table className="w-full text-left border-collapse">
                                <thead>
                                    <tr className="text-white bg-blue-600">
                                        <th className="p-4">Select</th>
                                        <th className="p-4">Department Name</th>
                                        <th className="p-4">Type</th>
                                        <th className="p-4">Exclude from Reports</th>
                                        <th className="p-4">Company Settings</th>
                                        <th className="p-4">Assign to All</th>
                                        <th className="p-4">Created At</th>
                                        <th className="p-4">Updated At</th>
                                        <th className="p-4">Edit/Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {departments.map((department) => (
                                        <tr
                                            key={department.id}
                                            className="border-b cursor-pointer hover:bg-gray-50"
                                            onClick={() => handleDepartmentClick(department.id)}
                                        >
                                            <td className="p-4" onClick={e => e.stopPropagation()}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedDepartments.includes(department.id)}
                                                    onChange={() => handleSelectDepartment(department.id)}
                                                />
                                            </td>
                                            <td className="p-4">{department.dp_name}</td>
                                            <td className="p-4">{department.dp_type}</td>
                                            <td className="p-4">{department.exclude_from_reports ? 'Yes' : 'No'}</td>
                                            <td className="p-4">{department.use_company_settings ? 'Yes' : 'No'}</td>
                                            <td className="p-4">{department.assign_to_all_employees ? 'Yes' : 'No'}</td>
                                            <td className="p-4">{new Date(department.created_at).toLocaleDateString()}</td>
                                            <td className="p-4">{new Date(department.updated_at).toLocaleDateString()}</td>
                                            <td className="p-4" onClick={e => e.stopPropagation()}>
                                                <FaEdit className="inline-block mr-2 text-lg text-green-500 cursor-pointer" onClick={(e) => { e.stopPropagation(); handleEdit(department); }} />
                                                <FaTrash className="inline-block text-lg text-red-500 cursor-pointer" onClick={(e) => { e.stopPropagation(); handleDelete(department.id); }} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>



            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} className="flex items-center justify-center">
                <div className="w-8/12 mx-auto">
                    <h2 className="mb-4 text-2xl font-bold">Edit Department</h2>
                    {currentDepartment && (
                        <form onSubmit={handleSaveChanges}>
                            <label className="block mb-2">Select Department:</label>
                            <select
                                value={currentDepartment.id}
                                onChange={(e) => {
                                    const selectedDept = departments.find(dept => dept.id === parseInt(e.target.value));
                                    setCurrentDepartment(selectedDept);
                                }}
                                className="w-full px-3 py-2 mb-4 border rounded"
                            >
                                <option value="" disabled>Select a department</option>
                                {departments.map(department => (
                                    <option key={department.id} value={department.id}>
                                        {department.dp_name}
                                    </option>
                                ))}
                            </select>
                            <label className="block mb-1 font-semibold text-gray-700" htmlFor="dp_type">
                                Department Type:
                            </label>
                            <select
                                id="dp_type"
                                name="dp_type"
                                value={currentDepartment.dp_type}
                                onChange={(e) => setCurrentDepartment({ ...currentDepartment, dp_type: e.target.value })}
                                className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                            >
                                <option value="Class">Class</option>
                                <option value="Client">Client</option>
                                <option value="Department">Department</option>
                                <option value="Group">Group</option>
                                <option value="Job">Job</option>
                                <option value="Job Site">Job Site</option>
                                <option value="Location">Location</option>
                                <option value="Office">Office</option>
                                <option value="Project">Project</option>
                                <option value="Task">Task</option>
                            </select>
                            <button
                                type="submit"
                                className="px-4 py-2 text-white bg-blue-500 rounded mt-7 hover:bg-blue-700"
                            >
                                Save Changes
                            </button>
                        </form>
                    )}
                </div>
            </Modal>

            <Footer />
        </>
    );
};

export default Departments;
