import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const CreateNewEmployee = () => {
    const navigate = useNavigate();
    const [showEmailNotifications, setShowEmailNotifications] = useState(false);
    const [showTimeStationApp, setShowTimeStationApp] = useState(false);
    const [showTimeStationSite, setShowTimeStationSite] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);
    const initialFormState = {
        name: '',
        department_id: '',
        employee_id: '',
        title: '',
        hourly_rate: '',
        pin: '',
        email_notification: false,
        email: '',
        permission_app: false,
        permission_site: false,
        send_check_in_notification: false,
        send_check_out_notification: false,
        solo_mode: false,
        use_card_or_pin: false,
        allow_other_employees: false,
        create_time_entries: false,
        edit_time_entries: false,
        punch_in_out: false,
        run_reports: false,
        view_gps_location: false,
        view_pay_information: false,
    };
    const [formData, setFormData] = useState(initialFormState);
    useEffect(() => {
        fetchDepartments();
    }, []);
    const fetchDepartments = async () => {
        try {
            const user_id = localStorage.getItem('user_id');
            const token = localStorage.getItem('token');

            const response = await fetch('https://timestation.clickbooster.io/api/departments', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch departments');
            }

            const data = await response.json()
            
            const userDepartments = data.filter(dept => dept.user_id === parseInt(user_id));
            setDepartments(userDepartments);
        } catch (error) {
            console.error('Error fetching departments:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id');

            const response = await fetch('https://timestation.clickbooster.io/api/employees', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    ...formData,
                    user_id: user_id
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to create employee');
            }
            
            toast.success('Employee created successfully!', {
                duration: 3000,
                position: 'top-right',
            });
            
            navigate('/employee');
            
            setFormData(initialFormState);
            
            setShowEmailNotifications(false);
            setShowTimeStationApp(false);
            setShowTimeStationSite(false);
            

        } catch (error) {
            console.error('Error creating employee:', error);
            toast.error(error.message || 'Failed to create employee', {
                duration: 3000,
                position: 'top-right',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>

            <Navbar />
            <ToastContainer/>
            <div className="flex justify-center min-h-screen p-6 bg-gray-100">
                <div className="w-full max-w-3xl p-6 bg-white rounded-lg shadow-md">
                    <h2 className="mb-6 text-2xl font-semibold text-gray-800">
                        Create New Employee
                    </h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {/* Name Field */}
                        <div>
                            <label className="block font-medium text-gray-600">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Enter Employee Name"
                                className="w-full px-4 py-2 mt-1 border rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                                required
                            />
                        </div>

                        {/* Department Dropdown */}
                        <div>
                            <label className="block font-medium text-gray-600">Department</label>
                            <select
                                name="department_id"
                                value={formData.department_id}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mt-1 border rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                                required
                            >
                                <option value="">Please Select</option>
                                {departments.map((dept) => (
                                    <option key={dept.id} value={dept.id}>
                                        {dept.dp_name} - {dept.dp_type}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Employee ID */}
                        <div>
                            <label className="block font-medium text-gray-600">Employee ID</label>
                            <input
                                type="text"
                                name="employee_id"
                                value={formData.employee_id}
                                onChange={handleInputChange}
                                placeholder="Enter Employee ID"
                                className="w-full px-4 py-2 mt-1 border rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                                required
                            />
                        </div>

                        {/* Title */}
                        <div>
                            <label className="block font-medium text-gray-600">Title</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                placeholder="Enter Title"
                                className="w-full px-4 py-2 mt-1 border rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                            />
                        </div>

                        {/* Hourly Rate */}
                        <div>
                            <label className="block font-medium text-gray-600">Hourly Rate</label>
                            <input
                                type="number"
                                name="hourly_rate"
                                value={formData.hourly_rate}
                                onChange={handleInputChange}
                                placeholder="Enter Hourly Rate"
                                className="w-full px-4 py-2 mt-1 border rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                            />
                        </div>

                        {/* Email Notifications Section */}
                        <div>
                            <label className="block font-medium text-gray-600">
                                <input
                                    type="checkbox"
                                    name="email_notification"
                                    checked={formData.email_notification}
                                    onChange={handleInputChange}
                                    className="mr-2"
                                />
                                Send Email Notification
                            </label>
                            {formData.email_notification && (
                                <div className="mt-2 ml-6 space-y-2">
                                    <div>
                                        <label className="block text-gray-600">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            placeholder="Enter Email"
                                            className="w-full px-4 py-2 mt-1 border rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                                        />
                                    </div>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="send_check_in_notification"
                                                checked={formData.send_check_in_notification}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Send Check-In Notification
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="send_check_out_notification"
                                                checked={formData.send_check_out_notification}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Send Check-Out Notification
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* PIN */}
                        <div>
                            <label className="block font-medium text-gray-600">4-Digit PIN</label>
                            <input
                                type="text"
                                name="pin"
                                value={formData.pin}
                                onChange={handleInputChange}
                                maxLength="4"
                                placeholder="Enter PIN"
                                className="w-full px-4 py-2 mt-1 border rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                            />
                        </div>

                        {/* Ai Time Stataion App - Updated with permission_app */}
                        <div>
                            <label className="block font-medium text-gray-600">
                                <input
                                    type="checkbox"
                                    name="permission_app"
                                    checked={formData.permission_app}
                                    onChange={handleInputChange}
                                    className="mr-2"
                                />
                                Login to the Ai Time Stataion App
                            </label>
                            {formData.permission_app && (
                                <div className="mt-2 ml-6 space-y-2">
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="solo_mode"
                                                checked={formData.solo_mode}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Solo Mode
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="use_card_or_pin"
                                                checked={formData.use_card_or_pin}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Use Card or PIN
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="allow_other_employees"
                                                checked={formData.allow_other_employees}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Allow Other Employees
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Ai Time Stataion Site - Updated with permission_site */}
                        <div>
                            <label className="block font-medium text-gray-600">
                                <input
                                    type="checkbox"
                                    name="permission_site"
                                    checked={formData.permission_site}
                                    onChange={handleInputChange}
                                    className="mr-2"
                                />
                                Login to the Ai Time Stataion Site
                            </label>
                            {formData.permission_site && (
                                <div className="mt-2 ml-6 space-y-2">
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="create_time_entries"
                                                checked={formData.create_time_entries}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Create Time Entries
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="edit_time_entries"
                                                checked={formData.edit_time_entries}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Edit Time Entries
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="punch_in_out"
                                                checked={formData.punch_in_out}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Punch In & Out
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="run_reports"
                                                checked={formData.run_reports}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Run Reports
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="view_gps_location_information"
                                                checked={formData.view_gps_location}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            View GPS Location Information
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="view_pay_information"
                                                checked={formData.view_pay_information}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            View Pay Information
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Updated Submit Button with loading state */}
                        <button
                            type="submit"
                            disabled={loading}
                            className={`w-full ${
                                loading 
                                    ? 'bg-blue-400 cursor-not-allowed' 
                                    : 'bg-blue-600 hover:bg-blue-700'
                            } text-white font-medium py-2 px-4 rounded-lg transition`}
                        >
                            {loading ? (
                                <span className="flex items-center justify-center">
                                    <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75 " fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Creating  Employee...
                                </span>
                            ) : (
                                'Create New Employee'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CreateNewEmployee;