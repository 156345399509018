import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import { FaPlus } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';

const APIKeys = () => {
    const [keys, setKeys] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentKey, setCurrentKey] = useState(null);

    // Fetch API keys from the server
    const fetchKeys = async () => {
        try {
            const response = await fetch('https://timestation.clickbooster.io/api/apikeys');
            const jsonData = await response.json();
            setKeys(jsonData.all_keys);
        } catch (err) {
            console.error(err);
            toast.error('Failed to fetch API keys');
        }
    };

    useEffect(() => {
        fetchKeys();
    }, []);

    // Toggle reveal or hide key
    const toggleReveal = (id) => {
        setKeys(
            keys.map((key) =>
                key.id === id ? { ...key, isRevealed: !key.isRevealed } : key
            )
        );
    };

    // Delete API key
    const deleteKey = async (id) => {
        try {
            await fetch(`https://timestation.clickbooster.io/api/apikeys/${id}`, {
                method: 'DELETE',
            });
            setKeys(keys.filter((key) => key.id !== id));
            toast.success('API Key deleted successfully');
        } catch (err) {
            console.error(err);
            toast.error('Failed to delete API key');
        }
    };

    // Edit API key
    const editKey = async (id, updatedKey) => {
        try {
            const response = await fetch(`https://timestation.clickbooster.io/api/apikeys/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedKey),
            });
            const jsonData = await response.json();

            // Update the local state with the updated key
            setKeys(keys.map((key) => (key.id === id ? { ...key, ...jsonData } : key)));
            toast.success('API Key updated successfully');
        } catch (err) {
            console.error(err);
            toast.error('Failed to update API key');
        }
    };

    // Open the modal for editing
    const openEditModal = (key) => {
        setCurrentKey({ ...key });
        setIsModalOpen(true);
    };

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentKey(null);
    };

    // Handle edit submission
    const handleEditSubmit = async () => {
        if (currentKey.key_name) {
            await editKey(currentKey.id, { key_name: currentKey.key_name });
            closeModal();
        } else {
            toast.error('Key name cannot be empty');
        }
    };

    return (
        <>
            <ToastContainer />
            <Navbar />
            <div className="container px-10 my-16 md:px-24">
                <div className="p-4 md:p-8">
                    <h2 className="mb-4 text-2xl font-semibold">API Keys</h2>
                    <Button text="Create API Key" link="/CreateApiKey" icon={<FaPlus />} />
                    <div className="w-full overflow-x-auto">
                        <table className="w-full mx-auto mt-6 overflow-hidden bg-white rounded-lg shadow-md">
                            <thead className="text-white bg-blue-600">
                                <tr>
                                    <th className="p-4 text-left">Key Name</th>
                                    <th className="p-4 text-left">Key</th>
                                    <th className="p-4 text-left">Created At</th>
                                    <th className="p-4 text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(keys) &&
                                    keys.map(({ id, key_name, key_value, created_at, isRevealed }) => (
                                        <tr key={id} className="border-b hover:bg-gray-100">
                                            <td className="p-4">{key_name}</td>
                                            <td className="p-4">
                                                <span className="inline-block w-full max-w-xs p-2 overflow-hidden bg-gray-100 rounded-md">
                                                    {isRevealed ? key_value : '••••••••••••'}
                                                </span>
                                            </td>
                                            <td className="p-4">
                                                {created_at
                                                    ? new Date(created_at).toLocaleDateString()
                                                    : 'Invalid Date'}
                                            </td>
                                            <td className="flex p-4 space-x-2">
                                                <button
                                                    className="px-3 text-white bg-yellow-500 rounded-md"
                                                    onClick={() => openEditModal({ id, key_name })}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    onClick={() => deleteKey(id)}
                                                    className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600"
                                                >
                                                    Delete
                                                </button>
                                                <button
                                                    onClick={() => toggleReveal(id)}
                                                    className="px-3 py-1 text-white bg-green-500 rounded hover:bg-green-600"
                                                >
                                                    {isRevealed ? 'Hide' : 'Reveal'}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />

            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="p-6 bg-white rounded-lg shadow-lg">
                        <h2 className="mb-4 text-xl font-semibold">Edit API Key</h2>
                        <input
                            type="text"
                            value={currentKey?.key_name || ''}
                            onChange={(e) =>
                                setCurrentKey({ ...currentKey, key_name: e.target.value })
                            }
                            className="w-full p-2 mb-4 border border-gray-300 rounded"
                        />
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={handleEditSubmit}
                                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                            >
                                Save
                            </button>
                            <button
                                onClick={closeModal}
                                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default APIKeys;
