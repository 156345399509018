import React, { useState, useEffect } from 'react';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

const CreateCustomField = () => {
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [fieldType, setFieldType] = useState('');
    const [fieldName, setFieldName] = useState('');
    const [allowDuplicates, setAllowDuplicates] = useState(false);

    // Fetch custom fields
    const fetchFields = async () => {
        setLoading(true);
        const userId = localStorage.getItem('user_id');
        if (!userId) {
            console.error("No user ID found in localStorage");
            alert("No user ID found. Please log in.");
            setLoading(false);
            return;
        }
    
        try {
            const response = await fetch(`https://timestation.clickbooster.io/api/fields/user/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setFields(data.data);
        } catch (error) {
            console.error('Fetch error:', error);
            alert('Failed to fetch fields: ' + error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchFields();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userId = localStorage.getItem('user_id');
        if (!userId) {
            console.error("No user ID found in localStorage");
            alert("No user ID found. Please log in.");
            return;
        }
        if (!fieldName || !fieldType) {
            alert("Please enter all required fields.");
            return;
        }
    
        setLoading(true);
        try {
            const response = await fetch(`https://timestation.clickbooster.io/api/fields`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    field_name: fieldName,
                    field_type: fieldType,
                    field_options: allowDuplicates.toString(),
                    user_id: userId
                })
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`HTTP error! status: ${response.status}. Message: ${JSON.stringify(errorData)}`);
            }
            await response.json();
            fetchFields(); // Refresh list after adding
            setShowModal(false); // Close modal after adding
            setFieldName(''); // Reset field name
            setFieldType(''); // Reset field type
            setAllowDuplicates(false); // Reset duplicates
        } catch (error) {
            console.error('Error creating field:', error);
            alert('Error creating field: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (fieldId) => {
        if (!window.confirm('Are you sure you want to delete this field?')) {
            return;
        }

        try {
            const response = await fetch(`https://timestation.clickbooster.io/api/fields/${fieldId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            fetchFields(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting field:', error);
            alert('Error deleting field: ' + error.message);
        }
    };

    const handleEdit = (fieldId) => {
        const fieldToEdit = fields.find(field => field.id === fieldId);
        if (fieldToEdit) {
            setFieldName(fieldToEdit.field_name);
            setFieldType(fieldToEdit.field_type);
            setAllowDuplicates(fieldToEdit.field_options === 'true');
            setShowModal(true);
        }
    };

    return (
        <>
            <Navbar />
            <div className="container px-4 py-8 mx-auto">
                <button
                    onClick={() => setShowModal(true)}
                    className="mb-4 px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-600"
                    style={{ display: 'block', width: 'fit-content' }}
                >
                    + Create New Custom Field
                </button>
                {fields.length > 0 && (
                    <div className="overflow-x-auto">
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Edit</th>
                                    <th scope="col" className="px-6 py-3">Field Name</th>
                                    <th scope="col" className="px-6 py-3">Type</th>
                                    <th scope="col" className="px-6 py-3">Allow Duplicates</th>
                                    <th scope="col" className="px-6 py-3">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.map(field => (
                                    <tr key={field.id} className="bg-white border-b">
                                        <td className="px-6 py-4"><FaPencilAlt className="text-blue-500 cursor-pointer" onClick={() => handleEdit(field.id)} /></td>
                                        <td className="px-6 py-4">{field.field_name}</td>
                                        <td className="px-6 py-4">{field.field_type}</td>
                                        <td className="px-6 py-4">{field.field_options.toString()}</td>
                                        <td className="px-6 py-4">
                                            <FaTrash className="text-red-500 cursor-pointer" onClick={() => handleDelete(field.id)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {fields.length === 0 && (
                    <div className="flex flex-col items-center justify-center h-screen">
                        <h2 className="text-4xl font-semibold">No Custom Fields</h2>
                        <p className="text-gray-500">No Custom Fields are currently set up.</p>
                    </div>
                )}
                {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
                        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                            <h2 className="text-2xl font-semibold mb-4">Create New Custom Field</h2>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <input
                                    type="text"
                                    placeholder="Field Name"
                                    value={fieldName}
                                    onChange={(e) => setFieldName(e.target.value)}
                                    className="w-full p-2 border rounded"
                                />
                                <select
                                    className="w-full p-2 border rounded"
                                    value={fieldType}
                                    onChange={(e) => setFieldType(e.target.value)}
                                >
                                    <option value="">Select Type</option>
                                    <option value="text">Text</option>
                                    <option value="number">Number</option>
                                    <option value="date">Date</option>
                                </select>
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={allowDuplicates}
                                        onChange={(e) => setAllowDuplicates(e.target.checked)}
                                        className="mr-2"
                                    />
                                    <span>Allow Duplicates</span>
                                </div>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        type="submit"
                                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                                    >
                                        Close
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default CreateCustomField;
