import React from "react";
import { FaExclamationTriangle, FaPlus } from "react-icons/fa";
import Button from "../../components/Button";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const CustomFields = () => {
    return (
        <>
            <Navbar />
            <div className="container shadow-xl">
                <div className="flex flex-col items-center justify-center h-screen bg-gray-50 p-6">
                    <h2 className="text-4xl font-semibold text-blue-600">Download</h2>

                    <div className="flex flex-col items-center mt-6 text-center">
                        <FaExclamationTriangle className="text-6xl text-gray-400 mb-4" />
                        <p className="text-gray-500 mb-2">
                            No Custom Fields are currently setup. <br />
                            Click button below to add your first custom field.
                        </p>

                        <div className="flex flex-col mt-4 space-y-4 w-full sm:w-auto">
                            <Button
                                text="Create a new field"
                                link="/CreateCustomField"
                                icon={<FaPlus />}
                                width="100%"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CustomFields;