import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const EmployeeCard = () => {
  const location = useLocation();
  const { id } = useParams(); // Employee ID from URL
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [punchData, setPunchData] = useState([]);
  const [loadingPunches, setLoadingPunches] = useState(true);
  const [errorPunches, setErrorPunches] = useState(null);

  // Fetch employee data from localStorage or location.state
  useEffect(() => {
    const storedEmployee = localStorage.getItem("employeeData");
    if (location.state?.employee) {
      setEmployeeData(location.state.employee);
      localStorage.setItem("employeeData", JSON.stringify(location.state.employee));
    } else if (storedEmployee) {
      setEmployeeData(JSON.parse(storedEmployee));
    } else {
      console.error("Employee data not found in localStorage or location.state");
    }
  }, [location.state]);

  // Fetch QR code
  useEffect(() => {
    const fetchQRCode = async () => {
      if (!employeeData?.id) return;

      try {
        const response = await fetch(
          `https://timestation.clickbooster.io/api/employees/qr/${employeeData.id}`
        );
        const data = await response.json();
        if (data.status === "success") {
          setQrCodeData(data.data);
        }
      } catch (error) {
        console.error("Error fetching QR code:", error);
      }
    };

    fetchQRCode();
  }, [employeeData]);

  // Fetch manual punch details
  useEffect(() => {
    const fetchPunchData = async () => {
      if (!id) return;

      try {
        const response = await fetch(
          `https://timestation.clickbooster.io/api/manual-punches/${id}`
        );
        if (!response.ok) {
          throw new Error(`Error fetching punch data: ${response.status}`);
        }

        const result = await response.json();
        if (result.data && Array.isArray(result.data)) {
          setPunchData(result.data);
        } else {
          setPunchData([]);
        }
      } catch (error) {
        console.error("Error fetching punch data:", error);
        setErrorPunches("Manual punch data not found !!!!");
      } finally {
        setLoadingPunches(false);
      }
    };

    fetchPunchData();
  }, [id]);

  const handlePrintCard = async () => {
    try {
      if (!employeeData) {
        console.error("Employee data is missing");
        return;
      }

      const response = await fetch(
        `https://timestation.clickbooster.io/api/employees/print/${employeeData.id}`
      );
      if (!response.ok) throw new Error("Failed to download card");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `employee-card-${employeeData.employee_id}.pdf`;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading card:", error);
    }
  };

  const calculateHours = (timeIn, timeOut) => {
    if (!timeIn || !timeOut) return "-";
    const startTime = new Date(`1970-01-01T${timeIn}`);
    const endTime = new Date(`1970-01-01T${timeOut}`);
    const diffInMilliseconds = endTime - startTime;
    const hours = diffInMilliseconds / (1000 * 60 * 60);
    return hours.toFixed(2);
  };

  if (!employeeData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Loading employee data...</p>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="p-4 mx-5 my-16 bg-gray-100 rounded-md shadow-lg md:mx-10">
        <div className="flex flex-col items-start justify-between mb-4 md:flex-row md:items-center">
          <h2 className="mb-4 text-2xl font-bold text-blue-600 md:mb-0">
            Employee Details and Manual Punch
          </h2>
          <div className="flex flex-col w-full gap-2 md:flex-row md:w-auto">
            <button
              onClick={handlePrintCard}
              className="w-full px-4 py-2 text-white bg-blue-500 rounded-md md:w-auto hover:bg-blue-600"
            >
              Print Card
            </button>
            <button
              onClick={() => navigate(`/ManualPunch/${employeeData.id}`)}
              className="w-full px-4 py-2 text-white bg-blue-500 rounded-md md:w-auto hover:bg-blue-600"
            >
              Manual Punch
            </button>
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-3">
          <div className="p-4 bg-white rounded-md shadow md:col-span-2">
            <h3 className="mb-2 text-lg font-semibold text-gray-800">
              Employee Info
            </h3>
            <p>
              <span className="font-bold">Name:</span> {employeeData.name}
            </p>
            <p>
              <span className="font-bold">Employee ID:</span>{" "}
              {employeeData.employee_id}
            </p>
            <p>
              <span className="font-bold">Title:</span> {employeeData.title}
            </p>
            <p>
              <span className="font-bold">Email:</span>{" "}
              <a
                href={`mailto:${employeeData.email}`}
                className="text-blue-500"
              >
                {employeeData.email}
              </a>
            </p>
            <p>
              <span className="font-bold">Department:</span>{" "}
              {employeeData.department}
            </p>
            <p>
              <span className="font-bold">Hourly Rate:</span> $
              {parseFloat(employeeData.hourly_rate).toFixed(2)}
            </p>
            <p>
              <span className="font-bold">Permissions:</span>{" "}
              {employeeData.permission_app && "App Access"}{" "}
              {employeeData.permission_site && "Site Access"}
            </p>
          </div>

          <div className="p-4 text-center bg-white rounded-md shadow">
            <h3 className="mb-2 text-lg font-semibold text-gray-800">
              Employee Card
            </h3>
            <div className="inline-block p-4 border rounded-md">
              {qrCodeData ? (
                <img
                  src={`data:image/svg+xml;base64,${qrCodeData.qr_code}`}
                  alt="QR Code"
                  className="w-20 h-20 mx-auto"
                />
              ) : (
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-2 text-2xl text-white bg-blue-500 rounded-full">
                  {employeeData.name.charAt(0).toUpperCase()}
                </div>
              )}
              <p className="text-xs text-gray-500">
                Employee ID: {employeeData.employee_id}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h3 className="mb-4 text-lg font-semibold text-gray-800">
            Manual Punch Details
          </h3>
          {loadingPunches ? (
            <p>Loading punch details...</p>
          ) : errorPunches ? (
            <p className="text-red-500">{errorPunches}</p>
          ) : punchData.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-4 py-2">Date</th>
                    <th className="px-4 py-2">Time In</th>
                    <th className="px-4 py-2">Time Out</th>
                    <th className="px-4 py-2">Hours</th>
                    <th className="px-4 py-2">Type</th>
                    <th className="px-4 py-2">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {punchData.map((punch, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="px-4 py-2">{punch.date}</td>
                      <td className="px-4 py-2">{punch.time_in}</td>
                      <td className="px-4 py-2">{punch.time_out}</td>
                      <td className="px-4 py-2">
                        {calculateHours(punch.time_in, punch.time_out)}
                      </td>
                      <td className="px-4 py-2">{punch.type}</td>
                      <td className="px-4 py-2">{punch.notes || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No punch details found.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmployeeCard;
