import React from 'react';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="py-8 text-white bg-black">
            <div className="container px-4 mx-auto">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                

                    {/* About Us Section */}
                
                    <div>
                        <h3 className="mb-4 text-lg font-semibold">About Us</h3>
                        <p className="text-sm">We are committed to providing a reliable and accurate employee attendance tracking system, ensuring seamless work management and productivity monitoring.</p>
                    </div>

                    {/* Quick Links Section */}
                    <div>
                        <h3 className="mb-4 text-lg font-semibold">Quick Links</h3>
                        <ul className="space-y-2">
                            {/* <li><Link to="/" className="text-sm hover:underline">Home</Link></li> */}
                            <li><Link to="/Privacy" className="text-sm hover:underline">Privacy</Link></li>
                            <li><Link to="/TermServices" className="text-sm hover:underline">Term and Services</Link></li>
                            <li><Link to="/attendance" className="text-sm hover:underline">Employee Attendance</Link></li>
                            <li><Link to="/RunReport" className="text-sm hover:underline">Reports</Link></li>
                            <li><Link to="/ContactPage" className="text-sm hover:underline">Contact</Link></li>
                        </ul>
                    </div>

                    {/* Contact Info Section */}
                    <div>
                        <h3 className="mb-4 text-lg font-semibold">Contact Info</h3>
                        <p className="text-sm">Address: 456 Business Park, Suite 101, City, Country</p>
                        <p className="text-sm font-semibold">Email:support@aitimestation.com</p>
                        <p className="text-sm font-semibold">Email:sales@aitimestation.com</p>
                        <p className="text-sm font-semibold">Phone: (123) 456-7890</p>
                    </div>

                    {/* Social Media Section */}
                    {/* <div>
                        <h3 className="mb-4 text-lg font-semibold">Follow Us</h3>
                        <div className="flex space-x-4">
                            <Link to="#" className="text-sm hover:underline">Facebook</Link>
                            <Link to="#" className="text-sm hover:underline">Twitter</Link>
                            <Link to="#" className="text-sm hover:underline">LinkedIn</Link>
                            <Link to="#" className="text-sm hover:underline">Instagram</Link>
                        </div>
                    </div> */}

                </div>

                {/* Footer Bottom */}
                <div className="mt-8 text-center">
                    <p className="text-sm">&copy; {new Date().getFullYear()} Employee Attendance System. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
