import React from "react";
import Footer from "../../../components/Footer";
import FirstNavbar from "../../../components/LandingPage/FirstNavbar";
import DownloadSection from "../../../components/LandingPage/DownloadSection";

const Download = () => {
    return (
        <>
            <FirstNavbar />
            <DownloadSection />
            <Footer />
        </>

    )


}

export default Download;