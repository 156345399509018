import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const CreateApiKey = () => {
    const navigate = useNavigate();
    const [keyName, setKeyName] = useState('');
    const [keyValue, setKeyValue] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('key_name', keyName);
        formData.append('key_value', keyValue);

        try {
            const response = await fetch('https://timestation.clickbooster.io/api/apikeys', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (response.ok) {
                toast.success('API Key created successfully!');
                navigate('/APIKeys');
                console.log('Response:', result);
                // Reset form fields
                setKeyName('');
                setKeyValue('');
            } else {
                toast.error('Failed to create API Key. Please try again.');
                console.error('Error:', result);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while creating the API Key.');
        }
    };

    return (
        <>
        <ToastContainer />
            <Navbar />
            <div className="flex flex-col items-center min-h-screen p-4 pt-16 bg-gray-100">
                {/* Header Section */}
                <h1 className="mb-4 text-2xl font-bold text-blue-600">Create API Key</h1>

                {/* Form Container */}
                <div className="w-full max-w-md p-6 rounded-lg shadow-lg bg-blue-50">
                    <form onSubmit={handleSubmit} className="flex flex-col">
                        {/* Key Name Field */}
                        <div className="mb-4">
                            <label className="block mb-2 font-semibold text-gray-700">Key Name:</label>
                            <input
                                type="text"
                                value={keyName}
                                onChange={(e) => setKeyName(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter API key name"
                                required
                            />
                        </div>

                        {/* Key Value Field */}
                        <div className="mb-4">
                            <label className="block mb-2 font-semibold text-gray-700">Key Value:</label>
                            <input
                                type="text"
                                value={keyValue}
                                onChange={(e) => setKeyValue(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter API key value"
                                required
                            />
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="w-full px-4 py-2 font-semibold text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            Create API Key
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CreateApiKey;
