import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

const ReportSection = () => {
    // State to keep track of the selected option
    const [selectedReport, setSelectedReport] = useState('');

    // Handle change in dropdown selection
    const handleSelectChange = (e) => {
        setSelectedReport(e.target.value);
    };

    return (
        <>
            <Navbar />
            <div className="flex flex-col items-center py-10 bg-gray-50 min-h-screen">
                {/* Title */}
                <h1 className="text-3xl font-bold text-blue-600 mb-6">Reports</h1>

                {/* Dropdown Section */}
                <div className="bg-blue-50 p-6 rounded shadow-lg w-full max-w-md">
                    <label className="text-lg font-semibold text-gray-700 mb-2 block">
                        Select Report:
                    </label>
                    <div className="relative mb-6">
                        <select
                            className="w-full border border-gray-300 rounded px-4 py-2 appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600"
                            value={selectedReport}
                            onChange={handleSelectChange}
                        >
                            <option value="">- Please Select</option>
                            <option value="admin-list">Administrator List</option>
                            <option value="attendance-counter">Attendance Counter</option>
                            <option value="attendance-only">Attendance-Only</option>
                            <option value="device-status">Current Device Status</option>
                            <option value="employee-status">Current Employee Status</option>
                            <option value="daily-attendance">Daily Attendance & Absence</option>
                            <option value="department-list">Department List</option>
                            <option value="department-members">Department Members</option>
                            <option value="department-summary">Department Summary</option>
                        </select>

                        {/* Dropdown Arrow */}
                        <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                            <svg
                                className="w-5 h-5 text-gray-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    </div>

                    {/* Conditionally render "Run Report" button */}
                    {selectedReport && (
                        <Button text="Run Report" link="/RunReport" width="100%" />
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ReportSection;
