import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';

const TimeStationCard = ({ title, description, content, cardStyle }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const card = cardRef.current;
    const enterAnimation = gsap.to(card, {
      scale: 1.05,
      duration: 0.3,
      ease: 'power3.out',
      boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.2)',
      paused: true
    });

    const leaveAnimation = gsap.to(card, {
      scale: 1,
      duration: 0.3,
      ease: 'power3.out',
      boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
      paused: true
    });

    const onHover = () => enterAnimation.restart();
    const onHoverOut = () => leaveAnimation.restart();

    card.addEventListener('mouseenter', onHover);
    card.addEventListener('mouseleave', onHoverOut);

    return () => {
      // Cleanup animations and event listeners
      card.removeEventListener('mouseenter', onHover);
      card.removeEventListener('mouseleave', onHoverOut);
      enterAnimation.kill();
      leaveAnimation.kill();
    };
  }, []);

  return (
    <div
      ref={cardRef}
      className={`bg-white shadow-lg my-10 rounded-lg p-6 max-w-2xl mx-auto ${cardStyle}`}
    >
      <h2 className="text-2xl lg:text-3xl font-bold text-blue-700 mb-4">{title}</h2>
      <p className="text-gray-700 mb-4">{description}</p>
      <div className="text-gray-700 space-y-4">{content}</div>
    </div>
  );
};

TimeStationCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  cardStyle: PropTypes.string,
};

TimeStationCard.defaultProps = {
  cardStyle: '',
};

export default TimeStationCard;
