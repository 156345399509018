import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import DeviceDownload from "../../components/DeviceDownload";

const Device = () => {
    return(
        <>
        <Navbar/>
        <DeviceDownload/>
        <Footer/>

        </>
    )
}

export default Device;