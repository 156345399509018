import React, { useState, useEffect } from "react";
import NavbarUser from "../../../components/UserComponents/NavbarUser";
import Footer from "../../../components/Footer";

const HomeUser = () => {
    const [punchData, setPunchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Retrieve emp_id and employee_id from localStorage
    const empId = localStorage.getItem("emp_id");
    const employeeId = localStorage.getItem("employee_id");

    useEffect(() => {
        fetchPunchData();
    }, []);

    const fetchPunchData = async () => {
        try {
            const identifier = empId || employeeId; // Use emp_id if available, else fallback to employee_id
            if (!identifier) {
                setError("No employee identifier found.");
                setLoading(false);
                return;
            }

            const response = await fetch(
                `https://timestation.clickbooster.io/api/manual-punches/${identifier}`
            );
            const result = await response.json();
            console.log("API Response:", result);

            setPunchData(Array.isArray(result.data) ? result.data : []);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching punch data:", error);
            setError("Failed to fetch punch data");
            setLoading(false);
        }
    };

    const getCurrentStatus = () => {
        if (punchData.length > 0) {
            const sortedPunchData = punchData.sort((a, b) => new Date(b.date) - new Date(a.date));
            const latestPunch = sortedPunchData[0];
            const hours = calculateHours(latestPunch.time_in, latestPunch.time_out);
            return {
                timeIn: latestPunch.time_in,
                timeOut: latestPunch.time_out,
                date: latestPunch.date,
                hours: hours,
                notes: latestPunch.notes,
                type: latestPunch.type,
            };
        }
        return null;
    };

    const calculateHours = (timeIn, timeOut) => {
        if (!timeIn || !timeOut) return "-";

        const startTime = new Date(`1970/01/01 ${timeIn}`);
        const endTime = new Date(`1970/01/01 ${timeOut}`);

        const diffInMilliseconds = endTime - startTime;
        const hours = diffInMilliseconds / (1000 * 60 * 60);

        return hours.toFixed(2);
    };

    return (
        <>
            <NavbarUser />
            <div className="min-h-screen p-4 mt-20 sm:p-8 bg-gray-50">
                <h1 className="mb-8 text-3xl font-bold text-center text-blue-700">
                    Welcome to Your Dashboard
                </h1>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    {/* Recent Activity */}
                    <div className="bg-white border rounded-lg shadow-lg">
                        <div className="p-4 text-lg font-bold text-white bg-blue-600 rounded-t-lg">
                            Recent Activity
                        </div>
                        {loading ? (
                            <div className="p-6 text-center text-gray-500">Loading...</div>
                        ) : error ? (
                            <div className="p-6 text-center text-red-600">{error}</div>
                        ) : (
                            <div className="p-4 overflow-auto">
                                <table className="w-full text-sm text-left border">
                                    <thead>
                                        <tr className="text-gray-700 bg-gray-200">
                                            <th className="p-3 border">Date</th>
                                            <th className="p-3 border">Time In</th>
                                            <th className="p-3 border">Time Out</th>
                                            <th className="p-3 border">Hours</th>
                                            <th className="p-3 border">Notes</th>
                                            <th className="p-3 border">Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(punchData) && punchData.length > 0 ? (
                                            punchData.map((punch) => (
                                                <tr key={punch.id} className="hover:bg-gray-100">
                                                    <td className="p-3 border">{punch.date}</td>
                                                    <td className="p-3 border">{punch.time_in}</td>
                                                    <td className="p-3 border">{punch.time_out}</td>
                                                    <td className="p-3 border">
                                                        {calculateHours(
                                                            punch.time_in,
                                                            punch.time_out
                                                        )}
                                                    </td>
                                                    <td className="p-3 border">{punch.notes}</td>
                                                    <td className="p-3 border">{punch.type}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan="6"
                                                    className="p-3 text-center text-gray-500"
                                                >
                                                    No punch data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>

                    {/* Current Status */}
                    <div className="bg-white border rounded-lg shadow-lg">
                        <div className="p-4 text-lg font-bold text-white bg-blue-600 rounded-t-lg">
                            Current Status
                        </div>
                        <div className="p-6">
                            {loading ? (
                                <div className="text-center text-gray-500">Loading...</div>
                            ) : error ? (
                                <div className="text-center text-red-600">{error}</div>
                            ) : getCurrentStatus() ? (
                                <div className="p-4 bg-gray-100 rounded-lg">
                                    <p className="mb-2">
                                        <strong>Date:</strong> {getCurrentStatus().date}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Time In:</strong> {getCurrentStatus().timeIn}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Time Out:</strong> {getCurrentStatus().timeOut}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Hours:</strong> {getCurrentStatus().hours}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Notes:</strong> {getCurrentStatus().notes}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Type:</strong> {getCurrentStatus().type}
                                    </p>
                                </div>
                            ) : (
                                <div className="text-center text-gray-500">
                                    No punch data available
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default HomeUser;
