import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/logo-doubly.png';
import Button from '../Button';
import { Link, useNavigate } from 'react-router-dom';

const FirstNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch username from localStorage
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        }
    }, []);

    const toggleMenu = () => setIsOpen(!isOpen);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleLogout = () => {
        // Clear localStorage and redirect to login
        localStorage.clear();
        navigate('/Login');
    };

    return (
        <nav className="p-4 bg-black shadow-md">
            {/* Large Screen Navbar */}
            <div className="container items-center justify-between hidden mx-auto lg:flex">
                {/* Logo */}
                <Link to="/" className="text-blue-900 text-tofont-bold">
                    <img src={logo} width="130px" alt="Logo" />
                </Link>

                {/* Desktop Menu */}
                <div className="flex items-center space-x-4">
                    <Link
                        to="/"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 text-wh hover:text-white"
                    >
                        Home
                    </Link>
                    <Link
                        to="/HowItWork"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 text-wh hover:text-white"
                    >
                        How it Works
                    </Link>
                    <Link
                        to="/Pricing"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 text-wh hover:text-white"
                    >
                        Pricing
                    </Link>
                    <Link
                        to="/signup"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 text-wh hover:text-white"
                    >
                        Signup
                    </Link>
                    <Link
                        to="/Download"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 text-wh hover:text-white"
                    >
                        Download
                    </Link>

                    {/* Conditional Rendering for Dropdown */}
                    {username ? (
                        <div className="relative">
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700"
                            >
                                <span> {username}</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="w-5 h-5 ml-2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </button>

                            {dropdownOpen && (
                                <div className="absolute right-0 w-48 mt-2 bg-white rounded-md shadow-lg">
                                    {/* <Link
                                        to="/Profile"
                                        className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
                                    >
                                        Profile
                                    </Link> */}
                                    <button
                                        onClick={handleLogout}
                                        className="block w-full px-4 py-2 text-sm text-left text-red-600 hover:bg-gray-200"
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Button text="Login" link="/Login" />
                    )}
                </div>
            </div>

            {/* Mobile Screen Navbar */}
            <div className="container flex items-center justify-between mx-auto lg:hidden">
                {/* Logo */}
                <Link to="/" className="text-lg font-bold text-blue-900">
                    <img src={logo} width="130px" alt="Logo" />
                </Link>

                {/* Hamburger Button */}
                <button className="text-white" onClick={toggleMenu}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </button>
            </div>

            {/* Mobile Menu (when the hamburger button is clicked) */}
            {isOpen && (
                <div className="absolute left-0 z-50 flex flex-col items-center w-full py-4 mt-10 space-y-4 bg-black shadow-lg lg:hidden top-16">
                    <Link
                        to="/"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 hover:text-white"
                    >
                        Home
                    </Link>
                    <Link
                        to="/HowItWork"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 hover:text-white"
                    >
                        How it Works
                    </Link>
                    <Link
                        to="/Pricing"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 hover:text-white"
                    >
                        Pricing
                    </Link>
                    <Link
                        to="/signup"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 hover:text-white"
                    >
                        Signup
                    </Link>
                    <Link
                        to="/Download"
                        className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700 hover:text-white"
                    >
                        Download
                    </Link>

                    {/* Conditional Rendering for Dropdown in Mobile */}
                    {username ? (
                        <div className="relative">
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center p-2 font-semibold text-gray-800 transition duration-300 rounded-md hover:bg-blue-700"
                            >
                                <span> {username}</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="w-5 h-5 ml-2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </button>

                            {dropdownOpen && (
                                <div className="absolute right-0 w-48 mt-2 bg-white rounded-md shadow-lg">
                                    {/* <Link
                                        to="/Profile"
                                        className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
                                    >
                                        Profile
                                    </Link> */}
                                    <button
                                        onClick={handleLogout}
                                        className="block w-full px-4 py-2 text-sm text-left text-red-600 hover:bg-gray-200"
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Button text="Login" link="/Login" />
                    )}
                </div>
            )}
        </nav>
    );
};

export default FirstNavbar;
