import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserFriends, FaPrint, FaMobileAlt } from 'react-icons/fa';
import Button from './Button';

const WelcometoTime = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  // Check for user in local storage and redirect if not found
  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userId = localStorage.getItem('user_id');
    console.log('User Id: ', userId);
    if (!userEmail) {
      navigate('/Login');
    } else {
      setUser(userEmail);
    }
  }, [navigate]);

  console.log('User Email:', user);

  return (
    <div className="container px-4 mx-auto my-10 md:my-20 md:px-24">
      <div className="flex flex-col items-center px-6 py-10 mx-auto space-y-6 rounded-lg shadow-lg md:py-20 bg-gray-50 md:space-y-8">
        <h1 className="text-2xl font-bold text-center text-blue-600 md:text-5xl">
          Welcome to Ai Time Station
        </h1>
        <p className="px-4 text-center text-gray-500 md:px-0">
          You're only steps away from completing your account setup
        </p>

        {/* Steps Section */}
        <div className="w-full space-y-4">
          {steps.map((step, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-between p-4 space-y-4 transition-shadow bg-blue-100 rounded-lg md:flex-row hover:shadow-md md:space-y-0"
            >
              <div className="flex flex-col items-center w-full space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="flex items-center space-x-2">
                  <span className="text-xl font-bold text-center text-blue-600 md:text-3xl">{`Step ${step.number}`}</span>
                  <div className="text-3xl text-blue-600 md:text-4xl">
                    {step.icon}
                  </div>
                </div>
                <div className="flex flex-col w-full text-center md:ml-4 md:text-left">
                  <span className="text-lg font-medium text-blue-600 md:text-2xl">
                    {step.title}
                  </span>
                </div>
              </div>
              {/* Go Button */}
              <div className="flex justify-center w-full md:w-auto">
                <Button text="Go" link={step.link} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Steps data
const steps = [
  {
    number: 1,
    title: 'Enter, or import your employees',
    icon: <FaUserFriends />,
    link: '/Account_Employees',  // Adjusted to lead directly to the create employees page
  },
  {
    number: 2,
    title: 'Print your Employee Cards',
    icon: <FaPrint />,
    link: '/Employee',
  },
  {
    number: 3,
    title: 'Install Ai Time Stataion on a Smartphone or Tablet',
    icon: <FaMobileAlt />,
    link: '/Download',
  },
];

export default WelcometoTime;
