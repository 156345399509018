import React, { useState, useEffect } from "react";
import Button from "./Button";

const FAQ = () => {
    const [faqData, setFaqData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    useEffect(() => {
        const fetchFaqData = async () => {
            try {
                const response = await fetch(
                    "https://timestation.clickbooster.io/api/faqs"
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch FAQ data");
                }
                const result = await response.json();
                setFaqData(Array.isArray(result.data) ? result.data : []);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchFaqData();
    }, []);



    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="p-5 mx-auto max-w-7xl">
            <div className="container mx-auto">
                <header className="flex flex-col items-center justify-between px-4 py-4 space-y-4 border-b md:flex-row md:px-24 md:space-y-0">
                    <div className="text-lg font-semibold md:text-xl">Support Center</div>
                    <div className="flex items-center w-full space-x-2 md:w-auto">
                        <input
                            type="text"
                            className="w-full p-2 border rounded md:w-auto"
                            placeholder="Search the Ai Time Stataion Support Center"
                        />
                        <Button text="Search" link="/Support" />
                    </div>
                </header>
            </div>

            <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-2 lg:grid-cols-3">
                {/* Topics Section - Updated */}
                <div className="p-4 bg-white rounded shadow">
                    <h3 className="mb-4 text-lg font-semibold">Browse by Topic</h3>
                    <div className="space-y-4">
                        {faqData.length > 0 ? (
                            faqData.map((item) => (
                                <div key={item.id || Math.random()} className="font-semibold cursor-pointer hover:text-blue-600">
                                    {item.topic || item.category || 'General'}
                                </div>
                            ))
                        ) : (
                            <div>No topics available</div>
                        )}
                    </div>
                </div>

                {/* FAQ Accordion Section - Updated */}
                <div className="col-span-2 p-4 bg-white rounded shadow">
                    <h3 className="mb-4 text-lg font-semibold">FAQs</h3>
                    <div>
                        {faqData.length > 0 ? (
                            faqData.map((item, index) => (
                                <div key={item.id || Math.random()} className="mb-2 accordion-item">
                                    <button
                                        onClick={() => toggleAccordion(index)}
                                        className="w-full px-5 py-3 text-lg font-semibold text-left bg-gray-200 rounded-lg accordion-button focus:outline-none hover:bg-gray-300"
                                    >
                                        {item.question || item.title}
                                    </button>
                                    {activeIndex === index && (
                                        <div className="p-4 mt-2 rounded-lg accordion-content bg-gray-50">
                                            <p>{item.answer || item.content}</p>
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div>No FAQs available</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
