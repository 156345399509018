import React, { useState } from "react";
import axios from "axios";
import Footer from "../../components/Footer";
import NavbarUser from "../../components/UserComponents/NavbarUser";


const EmployeeQR = () => {
    const [qrCode, setQrCode] = useState(""); 
    const [employeeId, setEmployeeId] = useState(""); 
    const [employeeName, setEmployeeName] = useState(""); 
    const [message, setMessage] = useState(""); 
    const [loading, setLoading] = useState(false); 

    
    const storedEmployeeId = localStorage.getItem("emp_id");
    

    
    const generateQrCode = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://timestation.clickbooster.io/api/employee/${storedEmployeeId}/generate-qr`);
            console.log("QR Code API Response:", response.data);

            
            const qrCodeData = `data:image/svg+xml;base64,${response.data.qr_code}`;
            setQrCode(qrCodeData);
            setEmployeeId(response.data.employee_id);

            console.log("set emolyee", response.data.employee_id);

            setEmployeeName(response.data.name);

            console.log("set name", response.data.name);
            
            setMessage("QR code generated successfully!");
        } catch (error) {
            console.error("Error generating QR code:", error.response?.data || error.message);
            setMessage(error.response?.data?.message || "Error generating QR code.");
        } finally {
            setLoading(false);
        }
    };

    
    const scanQrCode = async () => {
        try {
            setLoading(true);
            const response = await axios.post("https://timestation.clickbooster.io/api/scan-qr", { employee_id: storedEmployeeId });
            console.log("Scan QR Code API Response:", response.data);
            setMessage(response.data.message);
        } catch (error) {
            console.error("Error scanning QR code:", error.response?.data || error.message);
            setMessage(error.response?.data?.message || "Error scanning QR code.");
        } finally {
            setLoading(false);
        }
    };

    
    const downloadQrCodeWithDetails = async () => {
        if (!qrCode || !employeeId || !employeeName) return;

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        const qrImage = new Image();
        qrImage.src = qrCode;

        qrImage.onload = () => {
            const width = 400;
            const height = 500;

            canvas.width = width;
            canvas.height = height;

            
            context.drawImage(qrImage, 50, 50, 300, 300);

            
            context.font = "20px Arial";
            context.textAlign = "center";
            context.fillStyle = "white";
            context.fillText(`Employee ID: ${employeeId}`, width / 2, 380);
            context.fillText(`Name: ${employeeName}`, width / 2, 420);

            
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = `employee_qr_code_${employeeId}.png`;
            link.click();
        };
    };

    return (
        <>
        <NavbarUser/>
            <div className="flex flex-col items-center min-h-screen text-white bg-gray-900">
                <h1 className="mt-10 mb-5 text-3xl font-bold">Employee QR Management</h1>

                {/* Button to generate QR code */}
                <button
                    onClick={generateQrCode}
                    disabled={loading}
                    className={`px-6 py-3 mb-5 rounded-lg ${loading ? "bg-white-600 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
                        }`}
                >
                    {loading ? "Generating QR..." : "Generate QR Code"}
                </button>

                {/* Display QR code and employee details */}
                {qrCode && (
                    <div className="flex flex-col items-center p-5 space-y-4 text-black bg-white rounded-lg shadow-lg bg-white-800">
                        <p className="text-lg font-semibold">
                            <span className="text-blue-400">Employee ID:</span> {employeeId}
                        </p>
                        <p className="text-lg font-semibold">
                            <span className="text-blue-400">Employee Name:</span> {employeeName}
                        </p>
                        <img src={qrCode} alt="Employee QR Code" className="w-64 h-64" />
                        <button
                            onClick={downloadQrCodeWithDetails}
                            className="px-6 py-3 bg-green-500 rounded-lg hover:bg-green-600"
                        >
                            Download QR Code with Details
                        </button>
                    </div>
                )}

                {/* Button to scan QR code */}
                {/* <button
                onClick={scanQrCode}
                disabled={loading}
                className={`px-6 py-3 mt-5 rounded-lg ${
                    loading ? "bg-gray-600 cursor-not-allowed" : "bg-yellow-500 hover:bg-yellow-600"
                }`}
            >
                {loading ? "Processing..." : "Scan QR Code"}
            </button> */}

                {/* Display messages */}
                {message && <p className="mt-5 text-lg font-semibold text-green-400">{message}</p>}
                
            </div>
            <Footer/>
        </>

    );
};

export default EmployeeQR;
