// src/components/UploadProfilePicture.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const UploadProfilePicture = ({ userId, initialProfileImageUrl, onUploadSuccess }) => {
    const [image, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [uploadMessage, setUploadMessage] = useState(null);
    const [uploadMessageType, setUploadMessageType] = useState(''); // 'success' or 'error'
    const [uploading, setUploading] = useState(false); // Tracks the upload process
    const [uploadError, setUploadError] = useState(null); // Stores any upload-related errors

    // Handle image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);

            // Create a preview URL
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        } else {
            setImage(null);
            setPreviewUrl(null);
        }
    };

    // Handle image upload
    const handleImageUpload = async (event) => {
        event.preventDefault();
        setUploadMessage(null); // Reset previous messages
        setUploadMessageType('');
        setUploadError(null); // Reset previous errors

        if (!image) {
            setUploadMessage('Please select an image to upload.');
            setUploadMessageType('error');
            return;
        }

        if (!userId) {
            setUploadMessage('User ID is missing. Please log in again.');
            setUploadMessageType('error');
            console.error('User ID is missing. Cannot upload image.');
            return;
        }

        const formData = new FormData();
        formData.append('profile_picture', image);
        formData.append('user_id', userId); // Include user_id in form data

        console.log('FormData entries:');
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}:`, pair[1]);
        }

        try {
            setUploading(true); // Start uploading
            const token = localStorage.getItem('token');
            console.log('Uploading image with token:', token); // Debugging log

            const response = await axios.post(
                'https://timestation.clickbooster.io/api/upload-profile',
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        // 'Content-Type': 'multipart/form-data', // Let Axios set this automatically
                    },
                }
            );

            console.log('Upload Response:', response.data);
            setUploadMessage(response.data.message || 'Profile picture uploaded successfully.');
            setUploadMessageType('success');

            // Reset the image selection and preview
            setImage(null);
            setPreviewUrl(null);

            // Update the local profile image URL
            if (response.data.profile_image_url) {
                if (onUploadSuccess) {
                    onUploadSuccess(response.data.profile_image_url);
                }
            }
        } catch (err) {
            console.error('Error uploading image:', err.response || err);
            setUploadMessage(err.response?.data?.message || 'An error occurred while uploading.');
            setUploadMessageType('error');
            setUploadError(err.response?.data?.message || 'Failed to upload profile picture.');
        } finally {
            setUploading(false); // End uploading
        }
    };

    // Clean up the preview URL when the component unmounts or when a new image is selected
    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    return (
        <div className="mt-8 p-6 bg-white rounded-lg shadow-lg">
            <h2 className="mb-4 text-xl font-bold text-center">Upload Profile Picture</h2>

            {/* Display Current Profile Image Above the Upload Form */}
            {initialProfileImageUrl && (
                <div className="flex justify-center mb-4">
                    <img
                        src={initialProfileImageUrl}
                        alt="Current Profile"
                        className="w-32 h-32 object-cover rounded-full border"
                    />
                </div>
            )}

            {/* Display Upload Messages Above the Form */}
            {uploadMessage && (
                <p className={`mt-4 text-center ${uploadMessageType === 'error' ? 'text-red-500' : 'text-green-500'}`}>
                    {uploadMessage}
                </p>
            )}
            {uploadError && (
                <p className={`mt-2 text-center text-red-500`}>
                    {uploadError}
                </p>
            )}

            {/* Upload Form */}
            <form onSubmit={handleImageUpload} className="flex flex-col md:flex-row items-center md:justify-center">
                <div className="flex flex-col items-center">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="mb-4 md:mb-0 md:mr-4"
                        aria-label="Choose Profile Picture"
                    />
                    <button
                        type="submit"
                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                        disabled={uploading} // Disabled When Uploading
                    >
                        {uploading ? 'Uploading...' : 'Upload'}
                    </button>
                </div>
            </form>

            {/* Display Preview Image Below the Form */}
            {previewUrl && (
                <div className="mt-4 flex justify-center">
                    <img
                        src={previewUrl}
                        alt="Preview"
                        className="w-32 h-32 object-cover rounded-full border"
                    />
                </div>
            )}
        </div>
    );

};

UploadProfilePicture.propTypes = {
    userId: PropTypes.string.isRequired,
    initialProfileImageUrl: PropTypes.string,
    onUploadSuccess: PropTypes.func,
};

UploadProfilePicture.defaultProps = {
    initialProfileImageUrl: null,
    onUploadSuccess: null,
};

export default UploadProfilePicture;
