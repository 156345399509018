import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ForgetPassword.css"; 
import { useNavigate } from "react-router-dom"; 

const ForgetPassword = () => {
    const [email, setEmail] = React.useState("");
    const navigator = useNavigate(); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("https://timestation.clickbooster.io/api/forgot-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            });
            if (response.ok) {
                toast.success("Password reset link sent");
                navigator("/login")
            } else {
                toast.error("Failed to send password reset link");
            }
        } catch (error) {
            toast.error("Failed to send password reset link");
        }
    };

    return (
        <div className="container-fluid">
            <ToastContainer />
            <div className="card_style">
                <h3 className="heading">Forgot Password</h3>
                <form className="form_style" onSubmit={handleSubmit}>
                    <input
                        className="input-style"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />
                    <button type="submit" className="submit-button">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgetPassword;
