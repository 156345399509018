import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Logout = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleLogout = async () => {
        setIsLoading(true);

        try {
            const token = localStorage.getItem('token');

            // Debug token
            console.log('Token being sent:', token);

            // Check if token exists
            if (!token) {
                console.log('No token found, forcing logout');
                handleForceLogout();
                return;
            }

            const response = await fetch('https://timestation.clickbooster.io/api/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'Accept': 'application/json'
                }
            });

            console.log('Logout response status:', response.status);

            if (response.status === 401) {
                console.log('Token expired or invalid, forcing logout');
                handleForceLogout();
                return;
            }

            if (!response.ok) {
                throw new Error('Logout failed');
            }

            handleForceLogout();

        } catch (error) {
            console.error('Logout error:', error);
            handleForceLogout();
        } finally {
            setIsLoading(false);
        }
    };

    const handleForceLogout = () => {
        // Clear all localStorage
        localStorage.clear();
        sessionStorage.clear();

        // Show success message
        toast.success('Logged out successfully');

        // Redirect to login page
        setTimeout(() => {
            navigate('/login');
        }, 1000);
    };

    return (
        <button
            onClick={handleLogout}
            disabled={isLoading}
            className="w-full px-1 py-1 text-sm text-left text-gray-800 hover:bg-gray-200 disabled:opacity-50"
        >
            {isLoading ? 'Logging out...' : 'Logout'}
        </button>
    );
};

export default Logout; 