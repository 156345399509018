// src/components/UserSetting.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Button, Spinner, Alert, Container } from 'react-bootstrap';
import Navbar from './Navbar';
import Footer from './Footer';
import QRCodeModal from './QRCodeModal';
import UploadProfilePicture from './UploadProfilePicture';
import EarningsModal from './EarningsModal';

const UserSetting = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [username, setUsername] = useState('');
    const [userId, setUserId] = useState(null);
    const [error, setError] = useState(null);
    const [exportMessage, setExportMessage] = useState(null);
    const [exportMessageType, setExportMessageType] = useState('');
    const [exportLoading, setExportLoading] = useState(false);
    const [isQrModalOpen, setIsQrModalOpen] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState(null);


    // Retrieve username and user_id from localStorage on mount
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedUserId = localStorage.getItem('user_id');

        if (storedUsername) {
            setUsername(storedUsername);
        } else {
            setUsername('Guest');
        }

        if (storedUserId) {
            setUserId(storedUserId);
        } else {
            console.warn('User ID not found in localStorage.');
            // Optionally, navigate to login if user_id is essential
            // navigate('/login');
        }
    }, [navigate]);

    // Fetch user data from API
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');

                if (!token) {
                    throw new Error('No authentication token found');
                }

                console.log('Token:', token);

                const response = await axios.get('https://timestation.clickbooster.io/api/get-user', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                console.log('Response:', response.data);

                if (response.data && response.data.user) {
                    const { name, username, email, phone_number, date_of_birth, qr_code_url, profile_image_url } = response.data.user;
                    setUserData({ name, username, email, phone_number, date_of_birth, qr_code_url });
                    setProfileImageUrl(profile_image_url || null);
                } else {
                    throw new Error("No data received from server");
                }
            } catch (err) {
                console.error('Error details:', err.response || err);

                if (err.response?.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('username');
                    navigate('/login');
                    return;
                }

                setError(err.response?.data?.message || err.message);
            }
        };

        if (userId) {
            fetchUserData();
        }
    }, [navigate, userId]);

    // Setup Axios interceptor for 401 errors
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response?.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('username');
                    navigate('/login');
                }
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, [navigate]);

    // Handle QR Code Modal Open
    const handleShowQrCode = () => {
        setIsQrModalOpen(true);
    };

    // Handle QR Code Modal Close
    const handleCloseQrModal = () => {
        setIsQrModalOpen(false);
    };

    // Handle PDF Export
    const handleExportPDF = async () => {
        setExportMessage(null);
        setExportMessageType('');
        setExportLoading(true);

        try {
            const token = localStorage.getItem('token');

            if (!token) {
                throw new Error('No authentication token found');
            }

            console.log('Initiating PDF export...');

            const response = await axios.get('https://timestation.clickbooster.io/api/export-pdf', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/pdf',
                },
                responseType: 'blob',
            });

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', `user_data_${userId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            setTimeout(() => {
                window.URL.revokeObjectURL(pdfUrl);
            }, 100);

            setExportMessage('PDF exported successfully!');
            setExportMessageType('success');
        } catch (error) {
            console.error('Error exporting PDF:', error);
            setExportMessage('Failed to export PDF. Please try again.');
            setExportMessageType('error');
        } finally {
            setExportLoading(false);
        }
    };

    // Handle Excel Export
    const handleExportExcel = async () => {
        setExportMessage(null);
        setExportMessageType('');
        setExportLoading(true);

        try {
            const token = localStorage.getItem('token');

            if (!token) {
                throw new Error('No authentication token found');
            }

            console.log('Initiating Excel export...');

            const response = await axios.get('https://timestation.clickbooster.io/api/export-excel', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                responseType: 'blob',
            });

            const excelBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const excelUrl = window.URL.createObjectURL(excelBlob);
            const link = document.createElement('a');
            link.href = excelUrl;
            link.setAttribute('download', `user_data_${userId}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            setTimeout(() => {
                window.URL.revokeObjectURL(excelUrl);
            }, 100);

            setExportMessage('Excel exported successfully!');
            setExportMessageType('success');
        } catch (error) {
            console.error('Error exporting Excel:', error);
            setExportMessage('Failed to export Excel. Please try again.');
            setExportMessageType('error');
        } finally {
            setExportLoading(false);
        }
    };

    return (
        <>
            <Navbar profileImageUrl={profileImageUrl} setProfileImageUrl={setProfileImageUrl} />
            <Container className="my-5">
                {error && (
                    <Alert variant="danger" className="text-center">
                        Error: {error}
                    </Alert>
                )}

                <Tabs
                    defaultActiveKey="userInfo"
                    id="user-settings-tabs"
                    className="mb-3"
                    fill
                    variant="pills" // Set variant to 'pills'
                >
                    {/* User Information Tab */}
                    <Tab eventKey="userInfo" title="User Information">
                        {userData ? (
                            <div className="p-4 bg-white rounded shadow">
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Name</th>
                                            <td>{userData.name}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Username</th>
                                            <td>{userData.username}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Email</th>
                                            <td>{userData.email}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Phone Number</th>
                                            <td>{userData.phone_number}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Date of Birth</th>
                                            <td>{userData.date_of_birth}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* Display Profile Image */}
                                {profileImageUrl && (
                                    <div className="mt-4 text-center">
                                        <img
                                            src={profileImageUrl}
                                            alt="Profile"
                                            className="img-thumbnail rounded-circle"
                                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="text-center">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        )}
                    </Tab>

                    {/* Upload Profile Picture Tab */}
                    <Tab eventKey="uploadPicture" title="Upload Profile Picture">
                        <div className="p-4 bg-white rounded shadow">
                            <UploadProfilePicture
                                userId={userId}
                                initialProfileImageUrl={profileImageUrl}
                                onUploadSuccess={(newUrl) => {
                                    console.log('New Profile Image URL:', newUrl);
                                    setProfileImageUrl(newUrl);
                                }}
                            />
                        </div>
                    </Tab>

                    {/* Export Your Data Tab */}
                    <Tab eventKey="exportData" title="Export Your Data">
                        <div className="p-4 bg-white rounded shadow">
                            <h4 className="mb-4 text-center">Export Your Data</h4>
                            <div className="d-flex flex-column align-items-center">
                                <Button
                                    onClick={handleExportPDF}
                                    variant="danger"
                                    className="mb-3 w-50"
                                    disabled={exportLoading}
                                    aria-label="Export Data as PDF"
                                >
                                    {exportLoading ? (
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                            {' Exporting PDF...'}
                                        </>
                                    ) : (
                                        'Export as PDF'
                                    )}
                                </Button>
                                <Button
                                    onClick={handleExportExcel}
                                    variant="success"
                                    className="w-50"
                                    disabled={exportLoading}
                                    aria-label="Export Data as Excel"
                                >
                                    {exportLoading ? (
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                            {' Exporting Excel...'}
                                        </>
                                    ) : (
                                        'Export as Excel'
                                    )}
                                </Button>
                            </div>
                            {exportMessage && (
                                <Alert variant={exportMessageType === 'success' ? 'success' : 'danger'} className="mt-4 text-center">
                                    {exportMessage}
                                </Alert>
                            )}
                        </div>
                    </Tab>

                    {/* Your QR Code Tab */}
                    <Tab eventKey="qrCode" title="Your QR Code">
                        <div className="p-4 text-center bg-white rounded shadow">
                            <h4 className="mb-4">Your QR Code</h4>
                            <Button
                                onClick={handleShowQrCode}
                                variant="primary"
                                disabled={isQrModalOpen}
                                aria-label="Show QR Code"
                            >
                                {isQrModalOpen ? 'Fetching QR Code...' : 'Show QR Code'}
                            </Button>
                        </div>
                    </Tab>
                </Tabs>
            </Container>

            {/* QR Code Modal */}
            <QRCodeModal
                isOpen={isQrModalOpen}
                onClose={handleCloseQrModal}
                userId={userId}
            />




            <Footer />
        </>
    );
};

export default UserSetting;
