// src/components/QRCodeModal.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";

const QRCodeModal = ({ isOpen, onClose }) => {
  const [qrCode, setQrCode] = useState(null); // State to store the QR code
  const [loading, setLoading] = useState(false); // State to show loading
  const [error, setError] = useState(null); // State to store errors

  // Fetch user_id and token from local storage
  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token"); // Assuming you store the token as 'token'

  // Function to fetch the QR code
  const fetchQrCode = async () => {
    if (!userId) {
      setError("No user ID found in local storage.");
      return;
    }

    if (!token) {
      setError("No authentication token found.");
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const response = await axios.post(
        "https://timestation.clickbooster.io/api/generate-qr-code",
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Corrected Authorization header with backticks
          },
        }
      );
      if (response.data && response.data.qr_code) {
        setQrCode(response.data.qr_code);
      } else {
        setError("Failed to generate QR code.");
      }
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchQrCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]); // Fetch QR code when the modal is opened

  if (!isOpen) return null; // Do not render the modal if it's not open

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute text-gray-500 top-2 right-2 hover:text-gray-700"
          aria-label="Close QR Code Modal"
        >
          &times;
        </button>

        <h1 className="mb-6 text-2xl font-bold text-center">Your QR Code</h1>

        {loading && <p className="text-center">Generating QR Code...</p>}
        {error && <p className="text-center text-red-500">{error}</p>}

        {!loading && !error && qrCode && (
          <div className="flex flex-col items-center">
            <img src={qrCode} alt="User QR Code" className="w-48 h-48 mb-4" />
            <button
              onClick={onClose}
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700"
            >
              Close
            </button>
          </div>
        )}

        {!loading && !error && !qrCode && (
          <div className="flex flex-col items-center">
            <p>No QR Code available. Please try again.</p>
            <button
              onClick={fetchQrCode}
              className="px-4 py-2 mt-4 text-white bg-blue-600 rounded hover:bg-blue-700"
            >
              Generate QR Code
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

QRCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired, // Controls the visibility of the modal
  onClose: PropTypes.func.isRequired, // Function to close the modal
};

export default QRCodeModal;
