import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FAQ from "../../components/FAQ";

const Support = () => {
    return(
        <>
        <Navbar/>
        <FAQ/>
        <Footer/>

        </>
    )
}

export default Support;