import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import FirstNavbar from '../../components/LandingPage/FirstNavbar';

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        const token = localStorage.getItem('token');
        const userDataString = localStorage.getItem('user');

        if (token && userDataString) {
            const user = JSON.parse(userDataString);
            if (user.user_type === 'employee') {
                navigate('/HomeUser');
            } else if (user.user_type === 'user') {
                navigate('/HomeSecond');
            }
        }
    }, [navigate]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            console.log('Sending login request with:', formData);

            const response = await fetch('https://timestation.clickbooster.io/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const responseBody = await response.text();
            console.log('Raw API Response:', responseBody);

            if (responseBody) {
                const data = JSON.parse(responseBody);
                console.log('Parsed data:', data);

                if (!response.ok) {
                    throw new Error(data.message || 'Login failed');
                }

                if (!data.access_token) {
                    throw new Error('Access token is missing from response');
                }

                if (!data.user_type) {
                    throw new Error('User type is missing from response');
                }

                // Store data in localStorage
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('userEmail', formData.email);
                localStorage.setItem('employee_id', data.employee_id);
                localStorage.setItem('emp_id', data.emp_id);

                // Store username from API response
                if (data.username) {
                    localStorage.setItem('username', data.username);
                } else {
                    console.error("Username is missing in API response.");
                }

                if (data.emp_id) {
                    localStorage.setItem('emp_id', data.emp_id);
                }

                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        user_id: data.user_id,
                        user_type: data.user_type,
                        email: formData.email,
                    })
                );

                toast.success('Login successful!');

                // Navigate based on user type
                if (data.user_type === 'employee') {
                    navigate('/HomeUser');
                } else if (data.user_type === 'user') {
                    navigate('/HomeSecond');
                }
            } else {
                throw new Error('Empty response from server');
            }
        } catch (err) {
            console.error('Login Error:', {
                error: err,
                message: err.message,
                stack: err.stack,
            });
            toast.error(err.message || 'Login failed');
        } finally {
            setLoading(false);
        }
    };



    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <FirstNavbar />
            <div className="bg-blue-100">
                <div className="container py-16">
                    <h1 className='my-6 text-2xl font-semibold text-center bg-blue-100'>
                        Login to manage employees, print cards and run reports
                    </h1>
                    <div className="flex items-center justify-center">
                        <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-lg">
                            <h2 className="mb-6 text-2xl font-bold text-center">Login</h2>
                            <form onSubmit={handleSubmit}>
                                {/* Email Field */}
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full p-3 mt-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        placeholder="Enter your email"
                                        required
                                    />
                                </div>

                                {/* Password Field */}
                                <div className="mb-6">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        className="w-full p-3 mt-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        placeholder="Enter your password"
                                        required
                                    />
                                </div>

                                {/* Submit Button */}
                                <Button
                                    text={loading ? "Logging in..." : "Login"}
                                    onClick={handleSubmit}
                                    type="submit"
                                    width="100%"
                                />
                            </form>

                            {/* Links */}
                            <div className="mt-4 text-center">
                                <p className="text-sm">
                                    Don't have an account?{' '}
                                    <Link to="/ForgetPassword" className="text-blue-500 hover:underline">
                                        Forget Password
                                    </Link>
                                </p>
                                <p className="text-sm">
                                    Register{' '}
                                    <Link to="/SignUp" className="text-blue-500 hover:underline">
                                        Sign Up
                                    </Link>
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Login;
