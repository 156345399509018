import React from 'react';
import Button from '../../components/Button';
import { FaPlus } from "react-icons/fa";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const CustomFieldDetail = () => {
    return (
        <>
        <Navbar/>
            <div className="container my-20 md:px24 px-10">
                <div className="bg-gray-100 shadow-lg rounded-lg py-14 p-4">
                    {/* Header */}
                    <h1 className="text-2xl font-bold text-blue-600 mb-4">Custom Filed</h1>

                    {/* Search Section */}
                    <div className="bg-white p-4 rounded shadow mb-6">
                        <div className="flex flex-col sm:flex-row items-center gap-4">
                            <input
                                type="text"
                                placeholder="Search by Department Name, Type"
                                className="w-full sm:w-auto flex-grow border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
                            />
                            <Button
                                text="Search"
                                link="#"
                                width="100%"
                            />
                        </div>
                    </div>

                    {/* Create New Department Button */}
                    <Button
                        text="Create New Custom Filed"
                        link="/CreateCustomField"
                        icon={<FaPlus />}

                    />

                    {/* Departments Table */}
                    <div className="bg-white mt-4 shadow rounded overflow-x-auto">
                        <table className="w-full text-left border-collapse">
                            <thead>
                                <tr className="bg-blue-600 text-white">
                                    <th className="p-4">Edit</th>
                                    <th className="p-4">Department Name</th>
                                    <th className="p-4">Type</th>
                                    <th className="p-4">Employees</th>
                                    <th className="p-4">Primary</th>
                                    <th className="p-4">In</th>
                                    <th className="p-4">Out</th>
                                    <th className="p-4">Membership</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-b">
                                    <td className="p-4">
                                        <img src="edit-icon.png" alt="Edit" className="w-6 h-6 inline-block" />
                                    </td>
                                    <td className="p-4">Developer</td>
                                    <td className="p-4">Client</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">
                                        <button className="bg-green-500 text-white font-semibold px-4 py-1 rounded hover:bg-green-600 transition duration-300">
                                            Members
                                        </button>
                                    </td>
                                </tr>
                                <tr className="border-b">
                                    <td className="p-4">
                                        <img src="edit-icon.png" alt="Edit" className="w-6 h-6 inline-block" />
                                    </td>
                                    <td className="p-4">Developer</td>
                                    <td className="p-4">Client</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">
                                        <button className="bg-green-500 text-white font-semibold px-4 py-1 rounded hover:bg-green-600 transition duration-300">
                                            Members
                                        </button>
                                    </td>
                                </tr>
                                <tr className="border-b">
                                    <td className="p-4">
                                        <img src="edit-icon.png" alt="Edit" className="w-6 h-6 inline-block" />
                                    </td>
                                    <td className="p-4">Developer</td>
                                    <td className="p-4">Client</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">
                                        <button className="bg-green-500 text-white font-semibold px-4 py-1 rounded hover:bg-green-600 transition duration-300">
                                            Members
                                        </button>
                                    </td>
                                </tr>
                                <tr className="border-b">
                                    <td className="p-4">
                                        <img src="edit-icon.png" alt="Edit" className="w-6 h-6 inline-block" />
                                    </td>
                                    <td className="p-4">Developer</td>
                                    <td className="p-4">Client</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">0</td>
                                    <td className="p-4">
                                        <button className="bg-green-500 text-white font-semibold px-4 py-1 rounded hover:bg-green-600 transition duration-300">
                                            Members
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default CustomFieldDetail;
