import React from "react";
import Navbar from "../../components/Navbar";
import WelcometoTime from "../../components/WelcometoTime";
import Footer from "../../components/Footer";

const HomeSecond = () => {
    return(
        <>
        <Navbar/>
        <WelcometoTime/>
        <Footer/>

        </>
    )
}

export default HomeSecond;