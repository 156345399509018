import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Employee from "./pages/Employee";
import CreateNewEmployee from "./pages/CreateNewEmployee";
import ImportEmployees from "./pages/ImportEmployees";
import Departments from "./pages/Departments";
import CreateDepartment from "./pages/CreateDepartment";
import DepartmentsDetail from "./pages/DepartmentsDetail";
import ReportSelector from "./pages/ReportSelector";
import RunReport from "./pages/RunReport";
import Settings from "./pages/Settings";
import Support from "./pages/Support";
import Administrator from "./pages/Administrator";
import Device from "./pages/Device";
import CustomFields from "./pages/CustomFields";
import CreateCustomField from "./pages/CreateCustomField";
import CustomFieldDetail from "./pages/CustomFieldDetail";
import Pricing from "./pages/LandingPageMain/Pricing";
import CreateApiKey from "./pages/CreateApiKey";
import APIKeys from "./pages/APIKeys";
import UserProfile from "./pages/UserProfile";
import SignUp from "./pages/SignUp";
import EmployeesPage from "./pages/EmployeesPage";
import UserSetting from "./components/UserSetting";
import FAQS from "./pages/FAQS";
import EmployeeCard from "./pages/EmployeeCard";
import Activity from "./pages/Activity";
import ManualPunch from "./pages/ManualPunch";
import ActivityDetail from "./pages/ActivityDetail";
import HomeSecond from "./pages/HomeSecond";
import { ProtectedRoute } from './components/ProtectedRoute';
import PasswordSet from './pages/UserPages/PasswordSet';





//Landing Page
import Home from "./pages/Home";
import HowItWork from "./pages/LandingPageMain/HowItWork";
import Download from "./pages/LandingPageMain/Download";
import ResetPassword from "./pages/ResetPassword";
import HomeUser from "./pages/UserPages/HomeUser";
import ActivityUser from "./pages/UserPages/ActivityUser";
import ManualUserPunch from "./pages/UserPages/ManualUserPunch";
import UserReport from "./pages/UserPages/UserReport";
import SupportUser from "./pages/UserPages/SupportUser";
import ProfileInfoPage from "./pages/UserPages/UserProfileUser";
import DownloadUser from "./pages/DownloadUser";
import PricingUser from "./pages/PricingUser";
import ForgetPassword from "./pages/ForgetPassword";
import UserProfileUser from "./pages/UserPages/UserProfileUser";
import ActivityLogs from "./pages/ActivityLogs";
import EarningsModal from "./components/EarningsModal";
import EmployeeQR from "./pages/EmployeeQR";


const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes (Landing Page) - Accessible when not logged in */}

        <Route path="/" element={<Home />} />
        <Route path="/HowItWork" element={<HowItWork />} />
        <Route path="/HomeSecond" element={<HomeSecond />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Account_Employees" element={<EmployeesPage />} />
        <Route path="/Account_Setting" element={<UserSetting />} />
        <Route path="/FAQS" element={<FAQS />} />
        <Route path="/Employee/:id" element={<EmployeeCard />} />
        <Route path="/Activity" element={<Activity />} />
        <Route path="/ManualPunch:id" element={<ManualPunch />} />
        <Route path="/ActivityDetail" element={<ActivityDetail />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/ForgetPassword" element={<ForgetPassword />} />
        <Route path="/EarningsModal" element={<EarningsModal />} />
        <Route path="/EmployeeQR" element={<EmployeeQR />} />

        {/* Worker Panel Routes - Protected for employee type */}
        <Route path="/HomeUser" element={
          <ProtectedRoute userType="employee">
            <HomeUser />
          </ProtectedRoute>
        } />
        <Route path="/ActivityUser" element={
          <ProtectedRoute userType="employee">
            <ActivityUser />
          </ProtectedRoute>
        } />
        <Route path="/ManualUserPunch" element={
          <ProtectedRoute userType="employee">
            <ManualUserPunch />
          </ProtectedRoute>
        } />
        <Route path="/UserReport" element={
          <ProtectedRoute userType="employee">
            <UserReport />
          </ProtectedRoute>
        } />
        <Route path="/SupportUser" element={
          <ProtectedRoute userType="employee">
            <SupportUser />
          </ProtectedRoute>
        } />
        <Route path="/PasswordSet" element={
          <ProtectedRoute userType="employee">
            <PasswordSet />
          </ProtectedRoute>
        } />
        <Route path="/UserProfileUser" element={
          <ProtectedRoute userType="employee">
            <UserProfileUser />
          </ProtectedRoute>
        } />



        {/* User Panel Routes - Protected for user type */}
        <Route path="/Employee" element={
          <ProtectedRoute userType="user">
            <Employee />
          </ProtectedRoute>
        } />
        <Route path="/DownloadUser" element={
          <ProtectedRoute userType="user">
            <DownloadUser />
          </ProtectedRoute>
        } />
        <Route path="/PricingUser" element={
          <ProtectedRoute userType="user">
            <PricingUser />
          </ProtectedRoute>
        } />
        <Route path="/CreateNewEmployee" element={
          <ProtectedRoute userType="user">
            <CreateNewEmployee />
          </ProtectedRoute>
        } />
        <Route path="/ImportEmployees" element={
          <ProtectedRoute userType="user">
            <ImportEmployees />
          </ProtectedRoute>
        } />
        <Route path="/Departments" element={
          <ProtectedRoute userType="user">
            <Departments />
          </ProtectedRoute>
        } />
        <Route path="/CreateDepartment" element={
          <ProtectedRoute userType="user">
            <CreateDepartment />
          </ProtectedRoute>
        } />
        <Route path="/DepartmentsDetail" element={
          <ProtectedRoute userType="user">
            <DepartmentsDetail />
          </ProtectedRoute>
        } />
        <Route path="/ReportSelector" element={
          <ProtectedRoute userType="user">
            <ReportSelector />
          </ProtectedRoute>
        } />
        <Route path="/RunReport" element={
          <ProtectedRoute userType="user">
            <RunReport />
          </ProtectedRoute>
        } />
        <Route path="/Settings" element={
          <ProtectedRoute userType="user">
            <Settings />
          </ProtectedRoute>
        } />
        <Route path="/Support" element={
          <ProtectedRoute userType="user">
            <Support />
          </ProtectedRoute>
        } />
        <Route path="/Administrator" element={
          <ProtectedRoute userType="user">
            <Administrator />
          </ProtectedRoute>
        } />
        <Route path="/Device" element={
          <ProtectedRoute userType="user">
            <Device />
          </ProtectedRoute>
        } />
        <Route path="/CustomFields" element={
          <ProtectedRoute userType="user">
            <CustomFields />
          </ProtectedRoute>
        } />
        <Route path="/CreateCustomField" element={
          <ProtectedRoute userType="user">
            <CreateCustomField />
          </ProtectedRoute>
        } />
        <Route path="/CustomFieldDetail" element={
          <ProtectedRoute userType="user">
            <CustomFieldDetail />
          </ProtectedRoute>
        } />
        <Route path="/CreateApiKey" element={
          <ProtectedRoute userType="user">
            <CreateApiKey />
          </ProtectedRoute>
        } />
        <Route path="/APIKeys" element={
          <ProtectedRoute userType="user">
            <APIKeys />
          </ProtectedRoute>
        } />
        <Route path="/UserProfile" element={
          <ProtectedRoute userType="user">
            <UserProfile />
          </ProtectedRoute>
        } />
        <Route path="/Account_Employees" element={
          <ProtectedRoute userType="user">
            <EmployeesPage />
          </ProtectedRoute>
        } />
        <Route path="/Account_Setting" element={
          <ProtectedRoute userType="user">
            <UserSetting />
          </ProtectedRoute>
        } />
        <Route path="/FAQS" element={
          <ProtectedRoute userType="user">
            <FAQS />
          </ProtectedRoute>
        } />
        <Route path="/EmployeeCard/:id" element={
          <ProtectedRoute userType="user">
            <EmployeeCard />
          </ProtectedRoute>
        } />
        <Route path="/Activity" element={
          <ProtectedRoute userType="user">
            <Activity />
          </ProtectedRoute>
        } />
        <Route path="/ManualPunch/:id" element={
          <ProtectedRoute userType="user">
            <ManualPunch />
          </ProtectedRoute>
        } />
        <Route path="/ActivityDetail" element={
          <ProtectedRoute userType="user">
            <ActivityDetail />
          </ProtectedRoute>
        } />
        <Route path="/ActivityLogs" element={
          <ProtectedRoute userType="user">
            <ActivityLogs />
          </ProtectedRoute>
        } />
        <Route path="/Download" element={
          <ProtectedRoute userType="user">
            <Download />
          </ProtectedRoute>
        } />


      </Routes>
    </Router>
  );
};

export default App;