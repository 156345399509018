import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const ActivityDetail = () => {
    const location = useLocation();
    const punchData = location.state?.punchData;
    const message = location.state?.message;

    // Redirect if no data is present
    if (!punchData) {
        return <Navigate to="/manual-punch" replace />;
    }

    return (
        <>
            <Navbar />
            <div className="p-6 my-8">
                <h2 className="text-2xl font-bold mb-4">Punch Details</h2>

                {message && (
                    <div className="mb-4 p-4 bg-green-100 text-green-700 rounded-md">
                        {message}
                    </div>
                )}

                <div className="bg-white shadow rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr className="bg-blue-600">
                                <th scope="col" className="px-6 py-4 text-left text-sm font-medium text-white">Field</th>
                                <th scope="col" className="px-6 py-4 text-left text-sm font-medium text-white">Value</th>
                                <th scope="col" className="px-6 py-4 text-left text-sm font-medium text-white">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            <tr className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">Date</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{punchData.date}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <div className="flex space-x-2">
                                        <button className="text-green-600 hover:text-green-800">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button className="text-red-600 hover:text-red-800">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">Time In</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{punchData.time_in}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <div className="flex space-x-2">
                                        <button className="text-green-600 hover:text-green-800">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button className="text-red-600 hover:text-red-800">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">Time Out</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{punchData.time_out}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <div className="flex space-x-2">
                                        <button className="text-green-600 hover:text-green-800">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button className="text-red-600 hover:text-red-800">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">Hours</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{punchData.hours}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <div className="flex space-x-2">
                                        <button className="text-green-600 hover:text-green-800">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button className="text-red-600 hover:text-red-800">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">Deduction</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{punchData.deduction}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <div className="flex space-x-2">
                                        <button className="text-green-600 hover:text-green-800">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button className="text-red-600 hover:text-red-800">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">Type</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{punchData.type}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <div className="flex space-x-2">
                                        <button className="text-green-600 hover:text-green-800">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button className="text-red-600 hover:text-red-800">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            {punchData.notes && (
                                <tr className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">Notes</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{punchData.notes}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <div className="flex space-x-2">
                                            <button className="text-green-600 hover:text-green-800">
                                                <i className="fas fa-edit"></i>
                                            </button>
                                            <button className="text-red-600 hover:text-red-800">
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ActivityDetail;