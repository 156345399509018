import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const ActivityLogs = () => {
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ action: "", start_date: "", end_date: "" });

    const API_BASE = "https://timestation.clickbooster.io/api/activity-logs";

    useEffect(() => {
        fetchActivityLogs();
    }, [currentPage]);

    const fetchActivityLogs = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                toast.error("User is not authenticated. Please log in.");
                setLoading(false);
                return;
            }

            const queryParams = new URLSearchParams({
                page: currentPage,
                action: filters.action || "", // Ensure no `undefined` values
                start_date: filters.start_date || "",
                end_date: filters.end_date || "",
            }).toString();

            console.log("Request Payload:", queryParams); // Log the payload being sent

            const { data } = await axios.get(`${API_BASE}?${queryParams}`, {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,
            });

            setLogs(data.data || []);
            setCurrentPage(data.current_page || 1);
            setTotalPages(data.last_page || 1);

            if (data.data.length === 0) {
                toast.info("No activity logs found.");
            }
        } catch (error) {
            if (error.response) {
                console.error("Server Response Error:", error.response.data);
                toast.error(error.response.data.error || "Server error occurred.");
            } else if (error.request) {
                console.error("No response received from server:", error.request);
                toast.error("Network error. Please check your connection.");
            } else {
                console.error("Request Error:", error.message);
                toast.error("An error occurred. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };


    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const applyFilters = () => {
        setCurrentPage(1); // Reset to the first page
        fetchActivityLogs();
    };

    const handlePageChange = (direction) => {
        if (direction === "prev" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (direction === "next" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const exportLogsAsCSV = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                toast.error("User is not authenticated. Please log in.");
                return;
            }

            const queryParams = new URLSearchParams({
                action: filters.action || "",
                start_date: filters.start_date || "",
                end_date: filters.end_date || "",
            }).toString();

            const response = await axios.get(`${API_BASE}/export-csv?${queryParams}`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: "blob",
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "activity_logs.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("CSV downloaded successfully.");
        } catch (error) {
            console.error("Error exporting logs:", error);
            toast.error("Failed to export logs. Please try again.");
        }
    };


    return (
        <>
            <Navbar />
            <div className="p-4">
                <ToastContainer />
                <h2 className="mb-4 text-xl font-bold">Activity Logs</h2>

                {/* Filters */}
                <div className="mb-4">
                    <input
                        type="text"
                        name="action"
                        placeholder="Filter by action"
                        value={filters.action}
                        onChange={handleFilterChange}
                        className="p-2 mr-2 border"
                    />
                    <input
                        type="date"
                        name="start_date"
                        value={filters.start_date}
                        onChange={handleFilterChange}
                        className="p-2 mr-2 border"
                    />
                    <input
                        type="date"
                        name="end_date"
                        value={filters.end_date}
                        onChange={handleFilterChange}
                        className="p-2 mr-2 border"
                    />
                    <button onClick={applyFilters} className="px-4 py-2 text-white bg-blue-500 rounded">
                        Apply Filters
                    </button>
                </div>

                {loading ? (
                    <div className="text-center">Loading activity logs...</div>
                ) : (
                    <>
                        <table className="w-full border border-collapse">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-2 border">ID</th>
                                    <th className="p-2 border">User ID</th>
                                    <th className="p-2 border">Employee ID</th>
                                    <th className="p-2 border">Action</th>
                                    <th className="p-2 border">Details</th>
                                    <th className="p-2 border">Created At</th>
                                    <th className="p-2 border">Updated At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.length > 0 ? (
                                    logs.map((log) => (
                                        <tr key={log.id} className="border">
                                            <td className="p-2 border">{log.id}</td>
                                            <td className="p-2 border">{log.user_id || "N/A"}</td>
                                            <td className="p-2 border">{log.employee_id || "N/A"}</td>
                                            <td className="p-2 border">{log.action}</td>
                                            <td className="p-2 border">{log.details}</td>
                                            <td className="p-2 border">
                                                {log.created_at ? new Date(log.created_at).toLocaleString() : "N/A"}
                                            </td>
                                            <td className="p-2 border">
                                                {log.updated_at ? new Date(log.updated_at).toLocaleString() : "N/A"}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="p-2 text-center border">
                                            No activity logs available.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {/* Pagination Controls */}
                        <div className="flex justify-between mt-4">
                            <button
                                onClick={() => handlePageChange("prev")}
                                disabled={currentPage === 1}
                                className={`px-4 py-2 text-white bg-blue-500 rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                            >
                                Previous
                            </button>
                            <span className="px-4 py-2">
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange("next")}
                                disabled={currentPage === totalPages}
                                className={`px-4 py-2 text-white bg-blue-500 rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                            >
                                Next
                            </button>
                        </div>


                        <button
                            onClick={exportLogsAsCSV}
                            className="px-4 py-2 text-white bg-green-500 rounded"
                        >
                            Export as CSV
                        </button>

                    </>
                )}
            </div>
            <Footer />
        </>
    );
};

export default ActivityLogs;
