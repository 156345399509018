import React from "react";
import PromoBanner from "../../components/LandingPage/PromoBanner";
import TimeStationCard from "../../components/LandingPage/TimeStationCard";
import FeatureCard from "../../components/LandingPage/FeatureCard";
import {
    FaClock,
    FaRocket,
    FaCloud,
    FaChartLine,
    FaUser,
    FaMapMarkerAlt,
    FaSignal,
    FaNetworkWired,
    FaShieldAlt,
} from "react-icons/fa";
import Footer from "../../components/Footer";
import FirstNavbar from "../../components/LandingPage/FirstNavbar";
import img1 from "../../assets/images/fast.jpg";
import img2 from "../../assets/images/mobile.jpg";
import img3 from "../../assets/images/mind set.jpg";
import img4 from "../../assets/images/ratio.jpg";
import img5 from "../../assets/images/employee.jpg";
import img6 from "../../assets/images/location.jpg";

const Home = () => {
    const cardContent = (
        <>
            <p>
                Using our Fast-Scan technology, employees can punch In & Out in less than a second, and because Ai Time Station runs in the cloud, there’s no software or servers to maintain.
            </p>
            <p>
                Ai Time Station is the perfect solution for small and medium businesses looking to track time and attendance without the usual costs and overhead of traditional systems.
            </p>
            <p>
                The Ai Time Station mobile App works with smartphones and tablets running Google Android or Apple iOS including iPhone, iPad, and iPod Touch.
            </p>
        </>
    );

    const applicationsContent = (
        <ul className="space-y-2 text-gray-700 list-disc list-inside">
            <li>Track employee time and attendance for your business</li>
            <li>Track student attendance for your school</li>
            <li>Track member attendance for your club or group</li>
            <li>Track time spent across multiple projects or clients</li>
        </ul>
    );

    const features = [
        {
            icon: img1,
            title: "Get up fast and easy",
            subtitle:
                "Setup is fast and easy. Print employee id cards with photo and QR code for easy clock in & out. You will be up and running without delay.",
        },
        {
            icon: img2,
            title: "Al attendance scanner",
            subtitle:
                "AI-Scanner allows employees to clock in & out fast and easily. Employees can clock in & out manually or by simply scanning their badges even without a WIFI connection.",
        },
        {
            icon: img3,
            title: "Al Brain cloud",
            subtitle:
                "Al Brain cloud stores all the data for you and uploads it in a WI-FI friendly environment. You can manage and access time station data whenever there is internet.",
        },
        {
            icon: img4,
            title: "Al Report",
            subtitle:
                "The HR support team has access to multiple reports from data tracking. Reports can be exported and imported to different formats, allowing you easy access and integration with payroll ystems.",
        },
        {
            icon: img5,
            title: "Employee Portal",
            subtitle:
                "Using the portal is easy. Access the manager control panel, employees can access their profile and view activity, while manager have access to repair payroll issues",
        },
        {
            icon: img6,
            title: "Advance GPS Locator",
            subtitle:
                "Our GPS locator allows you to track employee while it shows their geographical location where employees punch in and out from.",
        },
        {
            icon: <FaSignal />,
            title: "Off-Line Mode",
            subtitle:
                "If Ai Time Station always has access to the internet, that’s why Ai Time Station can operate in off-line mode. It has more stable connections with a complete data update. When an internet connection becomes available, your device will automatically synchronize all its data with MyTimeStation.com.",
        },
        {
            icon: <FaNetworkWired />,
            title: "Use Multiple TimeStations",
            subtitle:
                "You can link multiple TimeStations to your company, allowing you to track time and attendance across different locations and departments.",
        },
        {
            icon: <FaShieldAlt />,
            title: "Security",
            subtitle:
                "TimeStation uses industry-standard SSL encryption for both MyTimeStation.com and the Ai Time Station Mobile App. This ensures that your data remains safe and secure end-to-end.",
        },
    ];

    return (
        <>
            <FirstNavbar />
            <PromoBanner />
            <div className="container mx-auto">
                <div className="grid grid-cols-1 gap-6 sm:px-3 lg:px:10 sm:p-1 md:px-24 md:grid-cols-2">
                    <TimeStationCard
                        title="Ai Time Station Features"
                        description="A comprehensive system for tracking time and attendance."
                        content={cardContent}
                    />
                    <TimeStationCard
                        title="Why Choose Ai Time Station?"
                        description="Perfect for small to medium-sized businesses."
                        content={applicationsContent}
                    />
                </div>
            </div>
            <div className="container mx-auto my-10">
                <div className="grid grid-cols-1 gap-3 cursor-pointer sm:px-3 lg:px-10 sm:p-1 md:px-24 md:grid-cols-2 lg:grid-cols-3">
                    {features.map((feature, index) => (
                        <FeatureCard
                            key={index}
                            icon={feature.icon}
                            title={feature.title}
                            subtitle={feature.subtitle}
                        />
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;
