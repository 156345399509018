import React from "react";
import Footer from "../../../components/Footer";
import ActivityUserPunch from "../../../components/UserComponents/ActivityUserPunch";
import NavbarUser from "../../../components/UserComponents/NavbarUser";




const ActivityUser = () => {
    return (
        <>
            <NavbarUser />
            <ActivityUserPunch/>
            <Footer />

        </>
    )
}

export default ActivityUser;