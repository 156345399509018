import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children, userType, requiredType }) => {
  // Get user data from localStorage or your auth context
  const userData = JSON.parse(localStorage.getItem('user')) || {};
  const isAuthenticated = !!localStorage.getItem('token'); // or however you check authentication

  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/Login" />;
  }

  if (userType !== userData.user_type) {
    // Redirect to appropriate homepage based on user type
    if (userData.user_type === "employee") {
      return <Navigate to="/HomeUser" />;
    } else if (userData.user_type === "user") {
      return <Navigate to="/Employee" />;
    }
  }

  return children;
}; 