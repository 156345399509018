import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManualPunch = () => {
    const navigate = useNavigate();
    const { id } = useParams(); 
    const [formData, setFormData] = useState({
        employee_id: "",
        time_in: "",
        time_out: "",
        deduction: "Auto",
        type: "None",
        notes: "",
        date: new Date().toISOString().split("T")[0],
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!id) {
            navigate("/login"); 
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            employee_id: id,
        }));
    }, [id, navigate]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateTimes = () => {
        const { time_in, time_out } = formData;
        if (time_in && time_out && time_in >= time_out) {
            toast.error("Time Out must be after Time In.");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateTimes()) return;

        setIsSubmitting(true);
        try {
            const response = await fetch(
                "https://timestation.clickbooster.io/api/manual-punches",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                }
            );

            const result = await response.json();
            if (response.ok) {
                toast.success(result.message || "Manual punch created successfully!");
                navigate(`/Employee/${id}`);
            } else {
                toast.error(result.message || "Failed to create punch");
                console.error("Error response:", result);
            }
        } catch (error) {
            toast.error("Error creating punch. Please try again.");
            console.error("Error creating punch:", error);
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <>
            <Navbar />
            <ToastContainer position="top-right" autoClose={3000} />
            <div className="flex flex-col items-center min-h-screen p-6 my-14 bg-gray-50">
                <h1 className="mb-8 text-3xl font-semibold text-blue-600">
                    Create Manual Punch
                </h1>
                <form
                    onSubmit={handleSubmit}
                    className="w-1/2 p-6 bg-white rounded-lg shadow-md"
                >
                    {/* Employee ID Field */}
                    <div className="hidden mb-4">
                        <label className="block mb-2 font-medium text-gray-700">
                            Employee ID:
                        </label>
                        <input
                            type="text"
                            name="employee_id"
                            value={formData.employee_id}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded-md"
                            readOnly
                        />
                    </div>

                    {/* Time In Field */}
                    <div className="mb-4">
                        <label className="block mb-2 font-medium text-gray-700">
                            Time In:
                        </label>
                        <input
                            type="time"
                            name="time_in"
                            value={formData.time_in}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded-md"
                            required
                        />
                    </div>

                    {/* Time Out Field */}
                    <div className="mb-4">
                        <label className="block mb-2 font-medium text-gray-700">
                            Time Out:
                        </label>
                        <input
                            type="time"
                            name="time_out"
                            value={formData.time_out}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded-md"
                            required
                        />
                    </div>

                    {/* Deduction Section */}
                    <div className="mb-4">
                        <label className="block mb-2 font-medium text-gray-700">
                            Deduction:
                        </label>
                        <div className="grid grid-cols-2 gap-2">
                            <select
                                name="deduction"
                                value={formData.deduction}
                                onChange={handleInputChange}
                                className="p-2 border rounded-md"
                            >
                                <option value="Auto">Auto</option>
                                <option value="Manual">Manual</option>
                            </select>
                            <select
                                name="type"
                                value={formData.type}
                                onChange={handleInputChange}
                                className="p-2 border rounded-md"
                            >
                                <option value="None">None</option>
                                <option value="Lunch">Lunch</option>
                                <option value="Break">Break</option>
                            </select>
                        </div>
                    </div>

                    {/* Notes Section */}
                    <div className="mb-4">
                        <label className="block mb-2 font-medium text-gray-700">Notes:</label>
                        <textarea
                            name="notes"
                            value={formData.notes}
                            onChange={handleInputChange}
                            placeholder="Add notes here..."
                            className="w-full p-2 border rounded-md"
                            rows="4"
                        ></textarea>
                    </div>

                    {/* Submit Button */}
                    <div className="text-center">
                        <button
                            type="submit"
                            className={`w-full px-4 py-2 text-white transition rounded-md ${isSubmitting
                                ? "bg-gray-400 cursor-not-allowed"
                                : "bg-blue-500 hover:bg-blue-600"
                                }`}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? "Submitting..." : "Create Manual Punch"}
                        </button>
                    </div>
                </form>
            </div>
            <Footer />
        </>
    );
};

export default ManualPunch;
