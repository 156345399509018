import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import axios from "axios";

const ReportSection = () => {
  const [selectedReport, setSelectedReport] = useState("");
  const [reportFields, setReportFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [reportData, setReportData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const reportFieldMapping = {
    "Administrator List": ["user_id"],
    "Department List": ["user_id"],
    "Department Members": ["user_id", "department_id"],
    "Department Summary": ["user_id", "start_date", "end_date"],
    "Employee List": ["user_id", "department_id", "show_deleted_employees"],
    "Employee Details": [
      "user_id",
      "employee_id",
      "department_id",
      "start_date",
      "end_date",
    ],
    "Employee Daily Summary": [
      "user_id",
      "employee_id",
      "department_id",
      "start_date",
      "end_date",
    ],
    "Employee Daily Summary - One Week": [
      "user_id",
      "employee_id",
      "department_id",
      "start_date",
      "end_date",
    ],
    "Employee Daily Summary - Two Week": [
      "user_id",
      "employee_id",
      "department_id",
      "start_date",
      "end_date",
    ],
    "Employee Weekday Summary": [
      "user_id",
      "employee_id",
      "department_id",
      "start_date",
      "end_date",
    ],
  };

  const handleReportChange = (report) => {
    setSelectedReport(report);
    setReportFields(reportFieldMapping[report] || []);
    const user_id = localStorage.getItem("user_id");
    setFormData({ user_id });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const fetchDropdownData = async (user_id) => {
    try {
      const [employeeRes, departmentRes] = await Promise.all([
        axios.get(`https://timestation.clickbooster.io/api/employees/user/${user_id}`),
        axios.get(`https://timestation.clickbooster.io/api/department/user/${user_id}`),
      ]);


      const employeeData = employeeRes.data.data;
      const departmentData = departmentRes.data.data;


      setEmployees(Array.isArray(employeeData) ? employeeData : employeeData ? [employeeData] : []);
      setDepartments(Array.isArray(departmentData) ? departmentData : departmentData ? [departmentData] : []);

    } catch (err) {
      console.error("Error fetching dropdown data:", err);
      setEmployees([]);
      setDepartments([]);
    }
  };

  const handleRunReport = async () => {
    if (!selectedReport) {
      alert("Please select a report.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.post(
        "https://timestation.clickbooster.io/api/getdata",
        { reportname: selectedReport, ...formData, user_id }
      );

      if (response.data && response.data.data) {
        const responseData = response.data.data;
        const formattedData = Array.isArray(responseData) ? responseData : [responseData];
        let filteredData = formattedData;

        // Define mapping for different report types
        const reportFormatters = {
          "Department List": (dept) => ({
            // 'Department ID': dept.id,
            'Department Name': dept.dp_name,
            'Description': dept.dp_type,
            'Created Date': dept.created_at,
          }),
          "Administrator List": (admin) => ({
            'Administrator ID': admin.id,
            'Department Name': admin.dp_name,
            'Email': admin.email,
            'Created Date': admin.created_at,
          }),
          "Department Members": (dept) => ({
            // 'Department ID': dept.id,
            'Department Name': dept.dp_name,
            'Employee ID': dept.employee_id,
            'Employee Name': dept.name,
            'Employee Email': dept.email,
            'Employee Status': dept.status,
            'Created Date': dept.created_at,
          }),
          "Department Summary": (dept) => ({
            // 'Department ID': dept.id,
            'Department Name': dept.dp_name,
            'Total Employees': dept.total_employees,
            'Total Active Employees': dept.total_active_employees,
            'Total Inactive Employees': dept.total_inactive_employees,
            'Total Deleted Employees': dept.total_deleted_employees,
            'Created Date': dept.created_at,
          }),
          "Employee List": (dept) => ({
            // 'Department ID': dept.id,
            'Department Name': dept.dp_name,
            'Employee ID': dept.employee_id,
            'Employee Name': dept.name,
            'Employee Email': dept.email,
            'Employee Status': dept.status,
            'Created Date': dept.created_at,
          }),
          "Employee Details": (dept) => ({
            // 'Department ID': dept.id,
            'Department Name': dept.dp_name,
            'Employee ID': dept.employee_id,
            'Employee Name': dept.name,
            'Employee Email': dept.email,
            'Employee Status': dept.status,
            'Created Date': dept.created_at,
          }),
          "Employee Daily Summary": (dept) => ({
            // 'Department ID': dept.id,
            'Department Name': dept.dp_name,
            'Employee ID': dept.employee_id,
            'Employee Name': dept.name,
            'Employee Email': dept.email,
            'Employee Status': dept.status,
            'Created Date': dept.created_at,
          }),
          "Employee Daily Summary - One Week": (dept) => ({
            // 'Department ID': dept.id,
            'Department Name': dept.dp_name,
          }),
          "Employee Daily Summary - Two Week": (dept) => ({
            // 'Department ID': dept.id,
            'Department Name': dept.dp_name,
          }),
          "Employee Weekday Summary": (dept) => ({
            // 'Department ID': dept.id,
            'Department Name': dept.dp_name,
          }),

        };

        // Apply formatter if exists for the selected report
        if (reportFormatters[selectedReport]) {
          filteredData = formattedData.map(reportFormatters[selectedReport]);
        }

        setReportData(filteredData);
        setTableHeaders(Object.keys(filteredData[0] || {}));

        console.log("Formatted Data:", filteredData);
        console.log("Table Headers:", Object.keys(filteredData[0] || {}));
      } else {
        setReportData([]);
        setTableHeaders([]);
        alert("No records found.");
      }
    } catch (err) {
      console.error("Error details:", err);
      setError("Error fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      fetchDropdownData(user_id);
      setFormData((prev) => ({ ...prev, user_id }));
    }
  }, []);

  console.log(employees, "employees");
  console.log(departments, "departments");

  return (
    <>
      <Navbar />

      <div className="container px-10 md:px-24">
        <div className="flex flex-col items-center min-h-screen py-10 bg-gray-50">
          <h1 className="mb-6 text-3xl font-bold text-blue-600">Reports</h1>

          {/* Report Selection */}
          <div className="w-full p-6 mb-6 rounded shadow-lg bg-blue-50">
            <label className="block mb-2 text-lg font-semibold text-gray-700">
              Select Report:
            </label>
            <div className="flex flex-col items-center gap-4 md:flex-row">
              <select
                className="w-full px-4 py-2 border border-gray-300 rounded appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600"
                value={selectedReport}
                onChange={(e) => handleReportChange(e.target.value)}
              >
                <option value="">Select a Report</option>
                {Object.keys(reportFieldMapping).map((report, index) => (
                  <option key={index} value={report}>
                    {report}
                  </option>
                ))}
              </select>
              <Button text="Run Report" onClick={handleRunReport} width="100%" />
            </div>
          </div>

          {/* Dynamic Fields */}
          {reportFields.length > 0 && (
            <div className="w-full p-6 mb-6 rounded shadow-lg bg-gray-50">
              <h2 className="mb-4 text-xl font-semibold text-blue-600">
                Fill Report Details:
              </h2>
              <form className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {reportFields
                  .filter(field => field !== "user_id")
                  .map((field, index) => (
                    <div key={index} className="flex flex-col">
                      <label className="mb-2 font-medium text-gray-700">
                        {field.replace(/_/g, " ").toUpperCase()}
                      </label>
                      {field === "employee_id" ? (
                        <select
                          className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                          onChange={(e) => handleInputChange(field, e.target.value)}
                          value={formData[field] || ""}
                        >
                          <option value="">Select an Employee</option>
                          {employees.map((employee) => (
                            <option key={employee.id} value={employee.employee_id}>
                              {employee.name}
                            </option>
                          ))}
                        </select>
                      ) : field === "department_id" ? (
                        <select
                          className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                          onChange={(e) => handleInputChange(field, e.target.value)}
                          value={formData[field] || ""}
                        >
                          <option value="">Select a Department</option>
                          {departments.map((department) => (
                            <option key={department.id} value={department.id}>
                              {department.dp_name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type={
                            field.includes("date")
                              ? "date"
                              : field === "show_deleted_employees"
                                ? "checkbox"
                                : "text"
                          }
                          className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                          onChange={(e) =>
                            handleInputChange(
                              field,
                              field === "show_deleted_employees"
                                ? e.target.checked
                                : e.target.value
                            )
                          }
                          value={
                            field === "show_deleted_employees"
                              ? undefined
                              : formData[field] || ""
                          }
                        />
                      )}
                    </div>
                  ))}
              </form>
            </div>
          )}

          {/* Dynamic Table */}
          <div className="w-full overflow-x-auto">
            {loading ? (
              <p className="text-center text-blue-600">Loading...</p>
            ) : error ? (
              <p className="text-center text-red-600">{error}</p>
            ) : (
              <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                <thead className="text-white bg-blue-600">
                  <tr>
                    {tableHeaders.map((header, index) => (
                      <th
                        key={index}
                        className="px-4 py-2 border border-blue-800"
                      >
                        {header.replace(/_/g, " ").toUpperCase()}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reportData.length > 0 ? (
                    reportData.map((record, index) => (
                      <tr key={index} className="text-center">
                        {tableHeaders.map((header, i) => (
                          <td
                            key={i}
                            className="px-4 py-2 border border-gray-200"
                          >
                            {typeof record[header] === 'object' && record[header] !== null
                              ? JSON.stringify(record[header])
                              : record[header] || "N/A"}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={tableHeaders.length}
                        className="py-4 text-center text-gray-500"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReportSection;
