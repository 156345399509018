// src/components/EarningsModal.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EarningsModal = ({ show, onHide }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('daily');

    useEffect(() => {
        if (show) {
            const fetchData = async () => {
                setLoading(true);
                setError(null);

                try {
                    const token = localStorage.getItem('token');
                    const employeeId = localStorage.getItem('employee_id');
                    if (!employeeId) {
                        throw new Error('Employee ID not found in localStorage.');
                    }

                    const response = await axios.post(
                        'https://timestation.clickbooster.io/api/with-deductions',
                        { employee_id: employeeId },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    );

                    setData(response.data.data);
                } catch (err) {
                    setError(err.response?.data?.message || 'Failed to fetch data.');
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [show]);

    const calculateTotalEarningsAfterDeductions = (deductions) =>
        deductions.reduce((total, deduction) => total + deduction.earnings_after_deduction, 0);

    const renderEarnings = (earnings) =>
        Object.entries(earnings).map(([date, amount]) => (
            <tr key={date}>
                <td className="p-2 border">{date}</td>
                <td className="p-2 border">{amount.toFixed(2)}</td>
            </tr>
        ));

    const renderDeductions = (deductions) =>
        deductions.map((deduction, index) => (
            <tr key={index}>
                <td className="p-2 border">{deduction.date_or_key}</td>
                <td className="p-2 border">{deduction.deduction_type}</td>
                <td className="p-2 border">{deduction.deduction_percentage}%</td>
                <td className="p-2 border">{deduction.original_earnings.toFixed(2)}</td>
                <td className="p-2 border">{deduction.deduction_amount.toFixed(2)}</td>
                <td className="p-2 border">{deduction.earnings_after_deduction.toFixed(2)}</td>
            </tr>
        ));

    return (
        show && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="w-11/12 max-w-6xl bg-white rounded-lg shadow-lg">
                    <div className="p-4">
                        <h2 className="mb-4 text-2xl font-bold text-center text-gray-800">
                            Earnings and Deductions
                        </h2>
                        <button
                            className="absolute px-3 py-1 text-sm text-white bg-red-600 rounded-md top-4 right-4 hover:bg-red-700"
                            onClick={onHide}
                        >
                            Close
                        </button>

                        {loading && (
                            <div className="flex items-center justify-center h-48">
                                <span className="text-lg font-medium text-gray-600">Loading...</span>
                            </div>
                        )}
                        {error && (
                            <div className="p-4 text-center text-red-600 bg-red-100 rounded-md">
                                {error}
                            </div>
                        )}
                        {data && (
                            <div>
                                {/* Pills Navigation */}
                                <div className="flex justify-center mb-4">
                                    {['daily', 'weekly', 'monthly'].map((tab) => (
                                        <button
                                            key={tab}
                                            onClick={() => setActiveTab(tab)}
                                            className={`px-4 py-2 mx-1 rounded-full text-sm font-medium ${
                                                activeTab === tab
                                                    ? 'bg-blue-600 text-white'
                                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                            }`}
                                        >
                                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                        </button>
                                    ))}
                                </div>

                                {/* Scrollable Content */}
                                <div className="max-h-[60vh] overflow-y-auto">
                                    <h5 className="mb-2 text-lg font-semibold text-gray-700">
                                        {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Earnings
                                    </h5>
                                    <table className="min-w-full mb-4 text-left border border-collapse border-gray-300">
                                        <thead className="bg-gray-200">
                                            <tr>
                                                <th className="p-2 border">Date</th>
                                                <th className="p-2 border">Earnings</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderEarnings(data.earnings[activeTab] || {})}
                                        </tbody>
                                    </table>

                                    <h5 className="mb-2 text-lg font-semibold text-gray-700">
                                        {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Deductions
                                    </h5>
                                    <table className="min-w-full mb-4 text-left border border-collapse border-gray-300">
                                        <thead className="bg-gray-200">
                                            <tr>
                                                <th className="p-2 border">Date</th>
                                                <th className="p-2 border">Type</th>
                                                <th className="p-2 border">Percentage</th>
                                                <th className="p-2 border">Original Earnings</th>
                                                <th className="p-2 border">Deduction</th>
                                                <th className="p-2 border">After Deduction</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderDeductions(data.deductions[activeTab] || [])}
                                        </tbody>
                                    </table>

                                    <div className="text-center">
                                        <h6 className="text-lg font-semibold text-green-600">
                                            Total Earnings After Deductions: $
                                            {calculateTotalEarningsAfterDeductions(
                                                data.deductions[activeTab] || []
                                            ).toFixed(2)}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default EarningsModal;
