import React from "react";

const FeatureCard = ({ icon, title, subtitle }) => (
    <div className="p-4 duration-300 ease-in-out scale-95 border rounded shadow-lg feature-card hover:scale-100">
        {icon ? (
            typeof icon === "string" ? (
                <img src={icon} alt={title} className="object-contain w-16 h-16 mx-auto mb-4 aspect-square" />
            ) : (
                <div className="w-16 h-16 mx-auto mb-4 text-4xl text-primary">{icon}</div>
            )
        ) : (
            <div className="w-16 h-16 mx-auto mb-4 bg-gray-200" />
        )}
        <h3 className="text-lg font-semibold text-center">{title}</h3>
        <p className="text-sm text-center text-gray-600">{subtitle}</p>
    </div>
);

export default FeatureCard;
